<template>
    <main role="main">

        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mt-5 mb-4">
                    <h1>éléments en attente de validation</h1>
                </div>
            </div>
        </div>

        <!-- Résultats -->
        <div id="back-search-results" class="mt-5 mb-5">
            <div class="container">
                <div class="row">
                    <div v-if="userPictures &&  userPictures.length" class="fullwidth">
                        <user-validation v-for="(user, index) in userPictures" :key="index" :user="user"></user-validation>

                        <div class="col-md-12 text-center mt-4 mb-2">
                            <a class="btn btn-secondary" @click.prevent="validateAll">Tout valider</a>
                        </div>
                    </div>
                    <div v-else>
                        <h3>Il n'y a pas d'éléments à valider</h3>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Résultats -->

    </main>
</template>

<script>
    import UserValidation from "../../components/admin/validation/UserValidation.vue"
    import axios from "axios";

    export default {
        name: "validation",
        components: {
            UserValidation
        },
        data() {
            return {
                userPictures: []
            }
        },
        methods: {
            setData(userPictures) {
                this.userPictures = userPictures
            },
            validateAll() {
                /* eslint-disable */
                axios.get(`/api/validation/validate-all-pictures`)
                    .then(
                        response => {
                            this.userPictures = []
                        }
                    )
                /* eslint-enable */
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.all([
                axios.get(`/api/validation/to-validate`),
            ]).then(
                axios.spread((userPictures) => {
                    next(vm => vm.setData(userPictures.data));
                })
            );
        },

    }
</script>