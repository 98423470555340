<template>
    <main role="main">
        <div class="pt-5 pb-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center mb-2">
                        <h1>{{ $t('titre page likes') }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div id="search-results" class="mt-5 mb-5">
            <div class="container">
                <div class="row">
                    <div class="profiles-grid d-flex">
                        <profile-bloc v-for="profile in profiles" :key="profile.userName" :profile="profile"></profile-bloc>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import ProfileBloc from "@/components/shared/ProfileBloc"
    import axios from "axios";

    export default {
        name: "likes",
        components: {
            ProfileBloc
        },
        data() {
            return {
                profiles: []
            }
        },
        mounted() {
            this.$store.dispatch('resetLikes')
            var ref = this
            this.$store.getters.getConnection.on("Connected", function (username, isConnected) {
                const user = ref.profiles.filter(p => p.userName == username)
                if (user != undefined && user != null) {
                    user[0].isOnline = isConnected;
                }
            })
        },
        beforeRouteEnter(to, from, next) {
            axios.get("/api/profiles/likes")
                .then(response => {
                    next(vm => vm.setData(response.data));
                })
        },
        methods: {
            setData(profiles) {
                console.log("set data profiles");
                this.profiles = profiles;
            }
        }

    };
</script>