<template>
    <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="profile in profiles" :key="profile.userName">
            <profile-bloc :profile="profile" class="carousel__item"></profile-bloc>
        </Slide>
        <template #addons v-if="showNavigation">
            <Navigation />
        </template>
    </Carousel>
</template>

<script>
    //import { defineComponent } from 'vue';
    import ProfileBloc from "@/components/shared/ProfileBlocCarousel.vue"
    import { Carousel, Navigation, Slide } from 'vue3-carousel';

    import 'vue3-carousel/dist/carousel.css';

    export default {
        name: 'carousel',
        components: {
            Carousel,
            Slide,
            Navigation,
            ProfileBloc
        },
        props: {
            profiles: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                settings: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                    wrapAround: true
                },
                // breakpoints are mobile first
                // any settings not specified will fallback to the carousel settings
                breakpoints: {
                    // 290px and up
                    290: {
                        itemsToShow: 2,
                    },
                    // 520px and up
                    520: {
                        itemsToShow: 3,
                    },
                    // 700px and up
                    750: {
                        itemsToShow: 4,
                    },
                    // 1024 and up
                    980: {
                        itemsToShow: 5,
                    },
                }
            }
        },
        computed: {
            showNavigation() {
                return this.profiles.length > 5
            },
        },
        mounted() {
            // Hack pour afficher le contenu au chargement
            var evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
            //window.addEventListener("resize", this.changeItemsNumber);
        },
        //methods: {
        //    changeItemsNumber() {
        //        let width = document.documentElement.clientWidth
        //    }
        //},
        //unmonted() {
        //    window.removeEventListener("resize", this.changeItemsNumber);
        //}

    }
</script>

<style>
    .carousel__item {
       /* min-height: 200px;
        width: 100%;
        color: var(--carousel-color-white);
        font-size: 20px;
        display: flex;*/
        justify-content: center;
        align-items: center;
    }

    .carousel__slide {
        padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
        border: 5px solid white;
        background-color: #fb782a;
        border: none;
        top: 35%;
        transform: none;
    }

    button.carousel__prev,
    button.carousel__next {
        outline: 0;
    }


    @media (min-width: 768px) {
        .carousel__prev {
            transform: translate(-50%,-50%);
        }
        .carousel__next {
            transform: translate(50%,-50%);
        }
    }


</style>