<template>
    <div class="flex-shrink-0 account-form-container">

        <div class="container">
            <!-- Formulaire d'inscription -->
            <form @submit.prevent="submit" class="form-signin" v-if="!isRegistered">
                <h1 class="h3 mb-3 font-weight-normal">{{ $t("S'inscrire") }}</h1>
                <div class="form-group">
                    <label for="LastName" class="sr-only">{{ $t('fields.lastName') }}</label>
                    <input type="text" v-model.trim="lastName" id="LastName" class="form-control" v-bind:placeholder="$t('fields.lastName')" required>
                </div>
                <div class="form-group">
                    <label for="FirstName" class="sr-only">{{ $t('fields.firstName') }}</label>
                    <input type="text" v-model.trim="firstName" id="FirstName" class="form-control" v-bind:placeholder="$t('fields.firstName')" required>
                </div>
                <div class="form-group">
                    <label for="Gender" class="sr-only">{{ $t('fields.f-gender') }}</label>
                    <div class="form-select-holder">
                        <custom-select-val-label class="test" :options="genders" @input="gender = $event" />
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-birthdate">{{ $t('fields.birthDate') }}</label>
                    <date-field :minYearFromToday="-100"  :maxYearFromToday="-18" @update="birthDate = $event" />
                </div>
                <div class="form-group">
                    <label for="Phone" class="sr-only">{{ $t('fields.phone') }}</label>
                    <input type="text" v-model.trim="phone" id="Phone" class="form-control" v-bind:placeholder="$t('fields.phone')" required>
                </div>
                <div class="form-group">
                    <label for="Email" class="sr-only">{{ $t('fields.email') }}</label>
                    <input type="email" v-model.trim="email" id="Email" class="form-control" v-bind:placeholder="$t('fields.email')" required>
                </div>
                <div class="form-group">
                    <label for="ZipCode" class="sr-only">{{ $t('fields.zipCode') }}</label>
                    <input type="text" v-model.trim="zipCode" id="ZipCode" class="form-control" v-bind:placeholder="$t('fields.zipCode')" required>
                </div>
                <div class="form-group">
                    {{ $t('Je préfère être contacté(e) par') }}<br>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" v-model.trim="contactByEmailOrPhone" id="ContactByEmail" name="contactByEmailOrPhone" class="custom-control-input" value="1" required>
                        <label class="custom-control-label" for="ContactByEmail">{{ $t('Email') }}</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" v-model.trim="contactByEmailOrPhone" id="ContactByPhone" name="contactByEmailOrPhone" class="custom-control-input" value="2" required>
                        <label class="custom-control-label" for="ContactByPhone">{{ $t('fields.phone') }}</label>
                    </div>
                </div>
                <!-- Erreurs -->
                <div class="alert alert-danger small" role="alert" v-if="regErrors != null">
                    <div v-for="(error, index) in regErrors" :key="index">
                        {{ $t(error[0]) }}
                    </div>
                </div>
                <!--<div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" value="agree-with-conditions" v-model="conditions">
                    <label class="custom-control-label" for="customCheck1">
                        <small>
                            {{ $t('Je marque mon accord') }} 
                            <router-link :to="{ name: 'page', params: { 'locale': $i18n.locale, 'slug': 'conditions-generales' } }">{{ $t("conditions d'utilisation") }}</router-link> 
                            {{ $t('et la') }}
                            <router-link :to="{ name: 'page', params: { 'locale': $i18n.locale, 'slug': 'informations-legales' } }">{{ $t('politique de confidentialité') }}</router-link> 
                            {{ $t('du site') }}
                        </small>
                    </label>
                </div>-->
                <button class="btn btn-lg button-link btn-block" type="submit" :disabled="loading">{{ $t("Je m'inscris") }}</button>
                <p><small><i>{{ $t('conserv_inscript') }}</i></small></p>
            </form>
            <!-- ENDFormulaire d'inscription -->

            <!-- START Message de confirmation -->
            <div v-if="isRegistered">
                <div class="form-signin text-center pb-5">
                    <h1 class="h3 mb-3 font-weight-normal">{{ $t("Confirmation de votre inscription") }}</h1>
                    <p>{{ $t("confirm_inscr_1") }}</p>
                    <p>{{ $t("confirm_inscr_2") }}</p>
                    <p>{{ $t("confirm_inscr_3") }}</p>
                    <p>{{ $t("confirm_inscr_4") }} <a href="mailto:info@measensus.be">info@measensus.be</a>.</p>
                    <p>{{ $t("confirm_inscr_5") }}</p>
                    <p>{{ $t("confirm_inscr_6") }}</p>
                </div>
            </div>
            <!-- END Message de confirmation -->
        </div>

    </div>
</template>

<script>
    import DateField from "../components/shared/fields/DateField.vue"
    import CustomSelectValLabel from "@/components/shared/fields/CustomSelectValLabel.vue";

    export default {
        name: "register-form",
        components: {
            DateField,
            CustomSelectValLabel
        },
        data() {
            return {
                lastName: "",
                firstName: "",
                language: "",
                gender: "",
                birthDate: "",
                phone: "",
                email: "",
                zipCode: "",
                contactByEmailOrPhone: "",
                conditions: false,
                isRegistered: false,
                regErrors: null,
                ip: ""
            };
        },
        computed: {
            loading() {
                return this.$store.state.loading;
            },
            genders() {
                return [
                    { value: "", label: this.$t('Sélectionnez votre genre') },
                    { value: "W", label: this.$t('fields.genders.W') },
                    { value: "M", label: this.$t('fields.genders.M') },
                    { value: "O", label: this.$t('fields.genders.O') }
                ]
            }
        },
        mounted() {
            this.getIp()
        },
        methods: {
            getIp() {
                fetch('https://api.ipify.org?format=json')
                    .then(x => x.json())
                    .then(({ ip }) => {
                        this.ip =  ip;
                    });
            },
            submit() {
                //if (!this.conditions) {
                //    this.regErrors = { error: ['accepter conditions'] }
                //} else {
                    const payload = {
                        lastName: this.lastName,
                        firstName: this.firstName,
                        language: this.$i18n.locale,
                        gender: this.gender,
                        birthDate: this.birthDate,
                        phone: this.phone,
                        email: this.email,
                        zipCode: this.zipCode,
                        contactByEmailOrPhone: this.contactByEmailOrPhone,
                        ipAddress: this.ip
                    };
                    this.$store
                        .dispatch("register", payload)
                        // eslint-disable-next-line no-unused-vars
                        .then(response => {
                            this.isRegistered = true;
                            window.scrollTo(0, 0);
                        })
                        .catch(error => {
                            if (typeof error.data === "string" || error.data instanceof String) {
                                this.regErrors = { error: [this.$t(error.data)] };
                            } else {
                                this.regErrors = error.data;
                            }
                        });
                //}
            },
            close() {
                this.regErrors = null;
            }
        }
    };
</script>

<style>
    @media (max-width: 575px) {
        .col-sm {
            flex-basis: 0 !important;
            flex-grow: 1 !important;
            max-width: 100% !important;
        }
    }
</style>