<template>
    <div id="profile-settings" class="mb-5">
        <div class="container">
            <div class="row">
                <div id="profile-settings-inner">
                    <div id="search-filters-settings-inner" class="mt-3">
                        <nav id="search-filters-settings-nav" class="nav nav-pills">
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='physical'}" href="#" @click.prevent="setTab('physical')" v-if="physicalFields && physicalFields.length">
                                <div class="search-nav-logo" id="tab-physical"><img src="../../../public/images/picto-tab-physique.svg" /></div><div class="search-nav-label">{{ $t('physique') }}</div>
                            </a>
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='emotional'}" href="#" @click.prevent="setTab('emotional')" v-if="emotionalFields && emotionalFields.length">
                                <div class="search-nav-logo" id="tab-emotional"><img src="../../../public/images/picto-tab-emotionnel.svg" /></div><div class="search-nav-label">{{ $t('personnalité') }}</div>
                            </a>
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='mental'}" href="#" @click.prevent="setTab('mental')" v-if="mentalFields && mentalFields.length">
                                <div class="search-nav-logo" id="tab-mental"><img src="../../../public/images/picto-tab-mental.svg" /></div><div class="search-nav-label">{{ $t('mental') }}</div>
                            </a>
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='spiritual'}" href="#" @click.prevent="setTab('spiritual')" v-if="spiritualFields && spiritualFields.length">
                                <div class="search-nav-logo" id="tab-spirit"><img src="../../../public/images/picto-tab-spiritualite.svg" /></div><div class="search-nav-label">{{ $t('spiritualité') }}</div>
                            </a>
                        </nav>
                        <div id="search-filters-settings-forms" class="tab-content">
                            <div :class="{'tab-pane':1,active: activeTab==='physical'}" id="physical" v-if="activeTab==='physical'">
                                <div class="list-item">
                                    <div class="list-item-content">
                                        <div class="list-label">{{ $t('Age') }}</div>
                                        <div class="list-value">{{ age + ' ' + $t('ans') }}</div>
                                    </div>
                                </div>
                                <div class="list-item">
                                    <div class="list-item-content">
                                        <div class="list-label">{{ $t('Ville') }}</div>
                                        <div class="list-value">{{ city }}</div>
                                    </div>
                                </div>
                                <fields-tab :fields="physicalFields"></fields-tab>

                            </div>
                            <div :class="{'tab-pane':1,active: activeTab==='emotional'}" id="emotional" v-if="activeTab==='emotional'">

                                <fields-tab :fields="emotionalFields"></fields-tab>

                            </div>
                            <div :class="{'tab-pane':1,active: activeTab==='mental'}" id="mental" v-if="activeTab==='mental'">

                                <fields-tab :fields="mentalFields"></fields-tab>

                            </div>
                            <div :class="{'tab-pane':1,active: activeTab==='spiritual'}" id="spiritual" v-if="activeTab==='spiritual'">

                                <fields-tab :fields="spiritualFields"></fields-tab>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FieldsTab from "./FieldsTab.vue"
    

    export default {
        name: "profile-fields-view",
        components: {
            FieldsTab
        },
        props: {
            fieldChoices: {
                type: Object,
                required: true
            },
            age: {
                Type: String
            },
            city: {
                Type: String
            }
        },
        data() {
            return {
                activeTab: 'physical',
            }
        },
        computed: {
            physicalFields() {
                return this.fieldChoices.filter(c => c.category==='physical')
            },
            emotionalFields() {
                return this.fieldChoices.filter(c => c.category === 'emotional')
            },
            mentalFields() {
                return this.fieldChoices.filter(c => c.category === 'mental')
            },
            spiritualFields() {
                return this.fieldChoices.filter(c => c.category === 'spiritual')
            },
        },
        methods: {
            setTab(tab) {
                this.activeTab = tab
            }
        }
    }
</script>