<template>
    <div id="search-filters-buttons" v-if="search">
        <!-- Quick Search -->
        <!------------------>
        <!-- gender -->
        <div class="tag-pills" v-if="search.quickSearch.gender.length>0">
            {{ $t('fields.f-gender') }}:
            <span v-for="(gender,index) in search.quickSearch.gender" :key="index"><span v-if="index>0">- </span>{{ $t('fields.genders.' + gender) }}</span>
            <span><a href="#" class="stretched-link" @click.prevent="resetField('gender', [])"><i class="fas fa-times"></i></a></span>
        </div>
        <!-- distance -->
        <div class="tag-pills" v-if="search.quickSearch.distance < 200">
            {{ $t('Distance') }}:
            <span>{{ search.quickSearch.distance }} km</span>
            <span><a href="#" class="stretched-link" @click.prevent="resetField('distance', 200)"><i class="fas fa-times"></i></a></span>
        </div>
        <!-- age -->
        <div class="tag-pills" v-if="search.quickSearch.minAge > 18 || search.quickSearch.maxAge < 99">
            {{ $t('Age') }}: {{ search.quickSearch.minAge }} {{ $t("à") }} {{ search.quickSearch.maxAge }} {{ $t("ans") }}
            <span><a href="#" class="stretched-link" @click.prevent="resetAge()"><i class="fas fa-times"></i></a></span>
        </div>
        <!-- pseudo -->
        <div class="tag-pills" v-if="search.quickSearch.pseudo != null && search.quickSearch.pseudo.trim() != ''">
            {{ $t('fields.userName') }}:
            <span>{{ search.quickSearch.pseudo }} </span>
            <span><a href="#" class="stretched-link" @click.prevent="resetField('pseudo', '')"><i class="fas fa-times"></i></a></span>
        </div>
        <!-- online -->
        <div class="tag-pills" v-if="search.quickSearch.isOnline">
            {{ $t('En ligne') }}
            <span><a href="#" class="stretched-link" @click.prevent="resetField('isOnline', false)"><i class="fas fa-times"></i></a></span>
        </div>
        <!-- Range fields -->
        <!------------------>
        <!-- height -->
        <div class="tag-pills" v-if="search.quickSearch.minHeight > 100 || search.quickSearch.maxHeight < 250">
            {{ $t('fields.f-height') }}: {{ search.quickSearch.minHeight }} {{ $t("à") }} {{ search.quickSearch.maxHeight }} kg
            <span><a href="#" class="stretched-link" @click.prevent="resetHeights"><i class="fas fa-times"></i></a></span>
        </div>
        <!-- weight -->
        <div class="tag-pills" v-if="search.quickSearch.minWeight > 35 || search.quickSearch.maxWeight < 200">
            {{ $t('fields.f-weight') }}: {{ search.quickSearch.minWeight }} {{ $t("à") }} {{ search.quickSearch.maxWeight }} kg
            <span><a href="#" class="stretched-link" @click.prevent="resetWeights"><i class="fas fa-times"></i></a></span>
        </div>

        <!-- Other fields -->
        <!------------------>
        <div class="tag-pills" v-for="field in pills" :key="field.field_Slug">
            {{ $t('fields.f-' + field.field_Slug) }}:
            <span v-for="(choice, index) in field.choices" :key="index"><span v-if="index>0">- </span>{{ getChoiceSlug(field.category, field.field_Slug, choice) }}</span>
            <span><a href="#" class="stretched-link" @click.prevent="clearChoices(field.category, field.field_Slug)"><i class="fas fa-times"></i></a></span>
        </div>

    </div>
</template>

<script>
    export default {
        name: "search-summary",
        props: {
            fieldsChoices: {
                type: Object,
                required: true
            },
            search: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                baseAndRangeFields: ['weight', 'height']
            }
        },
        computed: {
            locSearch: {
                get() { return this.search },
                set(locSearch) { this.$emit('input', locSearch) }
            },
            pills() {
                if (this.search != null) {
                    let userSearches = this.search.userSearches.filter(c => c.choices.length > 0 && !this.baseAndRangeFields.includes(c.field_Slug))
                    return userSearches
                }
                return null
            },

            //minHeight: {
            //    get() { return this.getUserChoice('min-height') },
            //    set(choice) { this.search.userSearches.filter(c => c.field_Slug === 'min-height')[0].choices[0] = choice }
            //},
            //maxHeight: {
            //    get() { return this.getUserChoice('max-height') },
            //    set(choice) { this.search.userSearches.filter(c => c.field_Slug === 'max-height')[0].choices[0] = choice }
            //},
            //minWeight: {
            //    get() { return this.getUserChoice('min-weight') },
            //    set(choice) { this.search.userSearches.filter(c => c.field_Slug === 'min-weight')[0].choices[0] = choice }
            //},
            //maxWeight: {
            //    get() { return this.getUserChoice('max-weight') },
            //    set(choice) { this.search.userSearches.filter(c => c.field_Slug === 'max-weight')[0].choices[0] = choice }
            //},
        },
        methods: {
            getChoiceSlug(category, field_slug, choice_id) {
                if (!this.baseAndRangeFields.includes(field_slug)) {
                    let categoryFields = this.fieldsChoices.filter(c => c.category === category)[0].fields
                    let choices = categoryFields.filter(f => f.slug == field_slug)
                    if (choices != undefined) {
                        if (choices[0] != undefined) {
                            if (choices[0].choices != undefined) {
                                if (choices[0].choices.filter(c => c.id == choice_id)[0] != undefined) {
                                    const choice_slug = choices[0].choices.filter(c => c.id == choice_id)[0].slug
                                    return this.$t("fields." + field_slug + "." + choice_slug)
                                }
                            }
                        }
                    }
                }
                return ''
            },
            clearChoices(category, field_slug) {
                let field = this.search.userSearches.filter(c => c.field_Slug === field_slug)
                if (field != undefined) {
                    field[0].choices = []
                }
                this.showSearch()
            },
            getUserChoice(slug) {
                let filtered = this.search.userSearches.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    let field = filtered[0]
                    if (field.choices.length) {
                        return field.choices[0]
                    }
                }
                return '';
            },
            resetField(field_slug, value) {
                this.locSearch.quickSearch[field_slug] = value
                this.$parent.getProfiles()
                this.showSearch()
            },
            resetAge() {
                this.locSearch.quickSearch.minAge = 18
                this.locSearch.quickSearch.maxAge = 99
                this.$parent.getProfiles();
                this.showSearch()
            },
            resetHeights() {
                this.locSearch.quickSearch.minHeight = 100
                this.locSearch.quickSearch.maxHeight = 250
                this.$parent.getProfiles();
                this.showSearch()
            },
            resetWeights() {
                this.locSearch.quickSearch.minWeight = 35
                this.locSearch.quickSearch.maxWeight = 200
                this.$parent.getProfiles();
                this.showSearch()
            },
            showSearch() {
                if (this.search.quickSearch.gender.length == 0
                    && this.search.quickSearch.distance == 200
                    && this.search.quickSearch.minAge == 18 && this.search.quickSearch.maxAge == 99
                    && (this.search.quickSearch.pseudo == null || this.search.quickSearch.pseudo.trim() == '')
                    && !this.search.quickSearch.isOnline
                    && this.search.quickSearch.minHeight == 100 && this.search.quickSearch.maxHeight == 250
                    && this.search.quickSearch.minWeight == 35 && this.search.quickSearch.maxWeight == 200
                    && this.pills.length == 0
                ) {
                    this.$parent.showTabs()
                }
            }
        }
    }
</script>