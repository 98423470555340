<template>
    <div class="form-group row">
        <label for="religion" class="col-lg-3 field-label">{{ $t('fields.f-religion') }}</label>
        <div class="col-lg-9">
            <custom-select-val-label :options="religionChoices" :selectid="religionUserChoice.toString()" @input="religionUserChoice = $event" />
        </div>
    </div>
    <div class="form-group row">
        <label for="astro-sign" class="col-lg-3 field-label">{{ $t('fields.f-astro-sign') }}</label>
        <div class="col-lg-9">
            <custom-select-val-label :options="astroSignChoices" :selectid="astroSignUserChoice.toString()" @input="astroSignUserChoice = $event" />
        </div>
    </div>
    <div class="form-group row">
        <label for="values" class="col-lg-3 field-label">{{ $t('fields.f-values') }} *</label>
        <div class="col-lg-9">
            <multi-select name="values" :items="valuesChoices" :selected="valuesUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="alter-care" class="col-lg-3 field-label">{{ $t('fields.f-alter-care') }}</label>
        <div class="col-lg-9">
            <multi-select name="alter-care" :items="alterCareChoices" :selected="alterCareUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="activity" class="col-lg-3 field-label">{{ $t('fields.f-activity') }}</label>
        <div class="col-lg-9">
            <multi-select name="activity" :items="activityChoices" :selected="activityUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="archangel" class="col-lg-3 field-label">{{ $t('fields.f-archangel') }}</label>
        <div class="col-lg-9">
            <multi-select name="archangel" :items="archangelChoices" :selected="archangelUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="eat" class="col-lg-3 field-label">{{ $t('fields.f-eat') }}</label>
        <div class="col-lg-9">
            <multi-select name="eat" :items="eatChoices" :selected="eatUserChoices"></multi-select>
        </div>
    </div>
</template>

<script>
    import MultiSelect from "../fields/MultiSelect.vue";
    import CustomSelectValLabel from "@/components/shared/fields/CustomSelectValLabel.vue";

    export default {
        name: "fields-spiritual",
        components: {
            MultiSelect,
            CustomSelectValLabel
        },
        props: {
            fields: {
                type: Object,
                required: true
            },
            userChoices: {
                type: Object,
                required: true
            }
        },
        methods: {
            getUserChoice(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    let field = filtered[0]
                    if (field.choices.length) {
                        return field.choices[0]
                    }
                }
                return '';
            },
            getUserChoices(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    return filtered[0].choices
                }
                return []
            }
        },
        computed: {
            religionChoices() {
                const religions = this.fields.filter(c => c.slug === 'religion')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < religions.length; i++) {
                    choices.push({ value: religions[i].id, label: this.$t('fields.religion.' + religions[i].slug) });
                }

                return choices
            },
            religionUserChoice: {
                get() { return this.getUserChoice('religion') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'religion')[0].choices[0] = choice }
            },

            astroSignChoices() {
                const astroSigns = this.fields.filter(c => c.slug === 'astro-sign')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < astroSigns.length; i++) {
                    choices.push({ value: astroSigns[i].id, label: this.$t('fields.astro-sign.' + astroSigns[i].slug) });
                }

                return choices
            },
            astroSignUserChoice: {
                get() { return this.getUserChoice('astro-sign') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'astro-sign')[0].choices[0] = choice }
            },

            valuesChoices() {
                return this.fields.filter(c => c.slug === 'values')[0].choices
            },
            valuesUserChoices: {
                get() { return this.getUserChoices('values') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'values')[0].choices = choices }
            },

            alterCareChoices() {
                return this.fields.filter(c => c.slug === 'alter-care')[0].choices
            },
            alterCareUserChoices: {
                get() { return this.getUserChoices('alter-care') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'alter-care')[0].choices = choices }
            },

            activityChoices() {
                return this.fields.filter(c => c.slug === 'activity')[0].choices
            },
            activityUserChoices: {
                get() { return this.getUserChoices('activity') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'activity')[0].choices = choices }
            },

            archangelChoices() {
                return this.fields.filter(c => c.slug === 'archangel')[0].choices
            },
            archangelUserChoices: {
                get() { return this.getUserChoices('archangel') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'archangel')[0].choices = choices }
            },

            eatChoices() {
                return this.fields.filter(c => c.slug === 'eat')[0].choices
            },
            eatUserChoices: {
                get() { return this.getUserChoices('eat') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'eat')[0].choices = choices }
            }
        },
    }
</script>