<template>
    <div class="flex-shrink-0 account-form-container">

        <!-- Formulaire d'identification -->
        <form @submit.prevent="send" class="form-signin">
            <h1 class="h3 mb-3 font-weight-normal">{{ $t("Mot de passe oublié") }}</h1>
            <div v-if="!isDone">
                <div class="form-group">
                    <label for="inputEmail" class="sr-only">{{ $t("fields.email") }}</label>
                    <input type="email" v-model.trim="email" id="Email" class="form-control" v-bind:placeholder="$t('fields.email')" required>
                </div>


                <button class="btn btn-lg button-link btn-block" type="submit">{{ $t("Envoyer") }}</button>
            </div>

            <div class="alert alert-danger" role="alert" v-if="failed">
                {{ $t("Une erreur s'est produite") }}
            </div>

            <div class="form-group" v-if="isDone">
                <p style="text-align:center;">{{ $t("Vous allez recevoir un e-mail pour réinitialiser votre mot de passe") }}.</p>
            </div>

        </form>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "login-form",
        data() {
            return {
                email: "",
                isDone: false,
                failed: false
            };
        },
        mounted() {
            this.email = this.$route.query.m || ""
        },
        methods: {
            send() {
                if (this.email.trim() != "") {
                    /* eslint-disable */
                    axios.post(`/api/account/forgot-password`, { email: this.email.trim(), language: this.$i18n.locale })
                        .then(
                            response => {
                                this.isDone = true
                                this.failed = false
                            }
                        ).catch(
                            error => {
                                this.failed = true
                            }
                        )
                    /* eslint-enable */
                }
            }
        }
    }
</script>

