<template>
    <a href="#" :class="{'chatroom-profile d-flex':true, active:currentChatter == chatter.userName}">
        <div class="chatroom-profile-status"><i class="fas fa-power-off" v-if="chatter.isOnline"></i></div>
        <div class="chatroom-profile-picture"><img :src="chatter.pictureCode" v-if="chatter.pictureCode"></div>
        <div class="chatroom-profile-data">
            <div class="chatroom-profile-header d-flex">
                <div class="chatroom-profile-pseudo">{{ chatter.pseudo }} <span class="badge badge-pill badge-primary" v-if="chatter.unreadMessageCount">{{ chatter.unreadMessageCount }}</span></div>
                <div class="chatroom-profile-lastactivity">{{ lastMessageDate }}</div>
            </div>
            <div class="chatroom-profile-message-summary" v-html="lastMessage"></div>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'chat',
        props: {
            chatter: {
                type: Object,
                required: true
            },
            currentChatter: {
                type: String
            }
        },
        computed: {
            lastMessage() {
                let lastMsg = this.chatter.lastMessage.message
                if (lastMsg != null) {
                    if (lastMsg.substr(0, 10) != 'data:image') {
                        lastMsg = lastMsg.replace(/<br>/g, ' ')
                        lastMsg = lastMsg.replace(/<div>/g, '')
                        lastMsg = lastMsg.replace(/<\/div>/g, '')
                        if (lastMsg.length > 27) {
                            return lastMsg.substring(0, 27) + '...'
                        } else {
                            return lastMsg
                        }
                    } else {
                        // it's an image
                        return '<i class="far fa-image"></i>'
                    }
                }
                return ''
            },
            lastMessageDate() {
                let now = new Date();
                now.setHours(0, 0, 0, 0)
                let messageDate = new Date(this.chatter.lastMessage.date)
                messageDate.setHours(0, 0, 0, 0)
                if (messageDate.getTime() == now.getTime()) {
                    return this.$t("aujourd'hui")
                } else {
                    return this.$filters.date(this.chatter.lastMessage.date)
                }
            }
        }
    }
</script>