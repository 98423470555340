import * as signalR from '@microsoft/signalr'
// Account
//export const showAuthModal = state => {
//    state.showAuthModal = true;
//};
//export const hideAuthModal = state => {
//    state.showAuthModal = false;
//};

//export const initialize = state => {
//     const store = localStorage.getItem("store");
//     if (store) {
//       Object.assign(state, JSON.parse(store));
//     }
// };
export const initialize = (state, payload) => {
    Object.assign(state, payload);
};


export const loginRequest = state => {
    state.loading = true;
};

export const loginSuccess = (state, payload) => {
    state.auth = payload;
    state.loading = false;
};

export const changeRole = (state, payload) => {
    state.auth.roles = [payload];
};

export const loginError = state => {
    state.loading = false;
};

export const registerRequest = state => {
    state.loading = true;
};
export const registerSuccess = state => {
    state.loading = false;
};
export const registerError = state => {
    state.loading = false;
};

export const logout = state => {
    state.auth = null;
};

export const setBackoffice = state => {
    state.isBackoffice = true;
};
export const unsetBackoffice = state => {
    state.isBackoffice = false;
};


export const setVisits = (state, payload) => {
    state.visitCount = payload
}
export const setShowVisits = (state, payload) => {
    state.showVisits = payload
}
export const setLikes = (state, payload) => {
    state.likeCount = payload
}
export const setShowLikes = (state, payload) => {
    state.showLikes = payload
}
export const setMessages = (state, payload) => {
    state.messageCount = payload
}
export const setShowMessages = (state, payload) => {
    state.showMessages = payload
}
export const setCloseToExpiration = (state, payload) => {
    state.closeToExpiration = payload
}

export const initConnection = (state, payload) => {
    state.connection = new signalR.HubConnectionBuilder()
        .withUrl("/chathub", { accessTokenFactory: () => payload })
        .withAutomaticReconnect()
        .build();
    state.connection.start({ pingInterval: 6000 });
}


//export const setStatus = (state, payload) => {
//    state.status = payload;
//};

