<template>
    <div class="container">
        <div id="profile-common-points-headline" class="d-flex">
            <div id="profile-common-points-headline-picto">
                <span><i class="fas fa-user-circle"></i></span>
                <span class="overlap"><i class="far fa-user-circle"></i></span>
            </div>
            <div>
                <h3>{{ title }}</h3>
                <a class="collapsed" href="#" aria-expanded="false" @click.prevent="isOpen=!isOpen"><span v-if="!isOpen">{{ $t("Voir plus") }} <i class="fas fa-angle-down"></i></span><span  v-if="isOpen"><i class="fas fa-times"></i> {{ $t("Fermer") }}</span></a>
            </div>
        </div>
        <transition name="slide">
            <div id="profile-common-points-data" v-if="isOpen">
                <div class="mt-4 mb-4">
                    <div class="list-item" v-for="field in fields" :key="field.slug">
                        <div class="list-item-content">
                            <div class="list-label">{{ $t('fields.f-' + field.field_Slug) }}</div>
                            <div class="list-value" v-for="choice in field.choices" :key="choice">{{translateChoice(field.field_Slug, choice) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        
    </div>
</template>

<script>
    export default {
        name: 'common-fields',
        data() {
            return {
                isOpen: false
            }
        },
        props: {
            fields: {
                type: Object,
                required: true
            }
        },
        computed: {
            title() {
                let count = 0;
                let i = 0
                for (i = 0; i < this.fields.length; i++) {
                    count += this.fields[i].choices.length
                }
                let titleText = this.$t('points communs')
                if (count == 1) {
                    titleText = this.$t('point commun')
                }
                return count + ' ' + titleText
            }
        },
        methods: {
            translateChoice(slug, choice) {
                if (this.isNumber(choice)) {
                    return choice
                } else {
                    return this.$t('fields.' + slug + '.' + choice)
                }
            },
            isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
        },
    }
</script>