<template>
    <div class="form-group row">
        <label for="character" class="col-lg-5 field-label">{{ $t('fields.f-character') }} *</label>
        <div class="col-lg-7">
            <multi-select name="character" :items="characterChoices" :selected="characterUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="pets" class="col-lg-5 field-label">{{ $t('fields.f-pets') }}</label>
        <div class="col-lg-7">
            <multi-select name="pets" :items="petsChoices" :selected="petsUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="hobbies" class="col-lg-5 field-label">{{ $t('fields.f-hobbies') }}</label>
        <div class="col-lg-7">
            <multi-select name="hobbies" :items="hobbiesChoices" :selected="hobbiesUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="movies" class="col-lg-5 field-label">{{ $t('fields.f-movies') }}</label>
        <div class="col-lg-7">
            <multi-select name="movies" :items="moviesChoices" :selected="moviesUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="restaurant" class="col-lg-5 field-label">{{ $t('fields.f-restaurant') }}</label>
        <div class="col-lg-7">
            <multi-select name="restaurant" :items="restaurantChoices" :selected="restaurantUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="reading" class="col-lg-5 field-label">{{ $t('fields.f-reading') }}</label>
        <div class="col-lg-7">
            <multi-select name="reading" :items="readingChoices" :selected="readingUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="music-instr" class="col-lg-5 field-label">{{ $t('fields.f-music-instr') }}</label>
        <div class="col-lg-7">
            <multi-select name="music-instr" :items="musicInstrChoices" :selected="musicInstrUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="music" class="col-lg-5 field-label">{{ $t('fields.f-music') }}</label>
        <div class="col-lg-7">
            <multi-select name="music" :items="musicChoices" :selected="musicUserChoices"></multi-select>
        </div>
    </div>
</template>

<script>
    import MultiSelect from "../fields/MultiSelect.vue";

    export default {
        name: "fields-Emotional",
        components: {
            MultiSelect
        },
        props: {
            fields: {
                type: Object,
                required: true
            },
            userChoices: {
                type: Object,
                required: true
            }
        },
        methods: {
            getUserChoices(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    return filtered[0].choices
                }
                return []
            }
        },
        computed: {
            characterChoices() {
                return this.fields.filter(c => c.slug === 'character')[0].choices
            },
            characterUserChoices: {
                get() { return this.getUserChoices('character') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'character')[0].choices = choices }
            },

            petsChoices() {
                return this.fields.filter(c => c.slug === 'pets')[0].choices
            },
            petsUserChoices: {
                get() { return this.getUserChoices('pets') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'pets')[0].choices = choices }
            },

            hobbiesChoices() {
                return this.fields.filter(c => c.slug === 'hobbies')[0].choices
            },
            hobbiesUserChoices: {
                get() { return this.getUserChoices('hobbies') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'hobbies')[0].choices = choices }
            },

            moviesChoices() {
                return this.fields.filter(c => c.slug === 'movies')[0].choices
            },
            moviesUserChoices: {
                get() { return this.getUserChoices('movies') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'movies')[0].choices = choices }
            },

            restaurantChoices() {
                return this.fields.filter(c => c.slug === 'restaurant')[0].choices
            },
            restaurantUserChoices: {
                get() { return this.getUserChoices('restaurant') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'restaurant')[0].choices = choices }
            },

            readingChoices() {
                return this.fields.filter(c => c.slug === 'reading')[0].choices
            },
            readingUserChoices: {
                get() { return this.getUserChoices('reading') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'reading')[0].choices = choices }
            },

            musicInstrChoices() {
                return this.fields.filter(c => c.slug === 'music-instr')[0].choices
            },
            musicInstrUserChoices: {
                get() { return this.getUserChoices('music-instr') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'music-instr')[0].choices = choices }
            },

            musicChoices() {
                return this.fields.filter(c => c.slug === 'music')[0].choices
            },
            musicUserChoices: {
                get() { return this.getUserChoices('music') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'music')[0].choices = choices }
            }
        },
    }
</script>