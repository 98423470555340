<template>
    <main role="main">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-2 mb-3">
                    <a href="javascript:history.back()" class="back-link"><span><i class="fas fa-arrow-left" aria-hidden="true"></i></span>{{ $t('Retour') }}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-5 text-center">
                    <h1>Edition du profil</h1>
                </div>
            </div>

            <div class="row">
                <!-- Colonne de gauche  -->
                <div class="col-md-3">
                    <!-- photo du profil  -->
                    <profile-picture :picture="profile.user.picture" :newPicture="profile.user.newPicture" 
                                     :pseudo="profile.user.pseudo" :slug="profile.user.slug" v-if="profile" @input="profile.user.newPicture = $event" ref="profilePict" />
                </div>
                <!-- END Colonne de gauche  -->

                <div class="col-md-9">
                    <form>
                        <div class="mb-5">
                            <div class="container">

                                <!-- FORMULAIRE PRINCIPAL -->
                                <user v-if="userData" :userData="userData" :statusList="statusList" :subscriptionTypes="subscriptionTypes" @error="isZipOrPwError = $event" :errors="errors"></user>

                                <!-- Photos supplémentaires -->
                                <pictures :userPictures="profile.user.userPictures" :slug="profile.user.slug" v-if="profile" @delete-picture-code="deletePictureCode($event)" @delete="profile.user.oldPictures = $event" @add="profile.user.newPictures = $event"></pictures>
                            </div>
                        </div>

                        <!-- TABS PHYSIQUE, PERSONNALITE, MENTAL, SPIRITUALITE -->
                        <user-fields :userFieldChoices="userFieldChoices" :fieldChoices="fieldChoices" v-if="fieldChoices"></user-fields>

                        <!-- Boutons -->
                        <div class="alert alert-danger" role="alert" v-if="isError || isZipOrPwError">
                            {{ $t('Veuillez vérifier la validité du formulaire') }}
                            <ul v-if="errors">
                                <li v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                        </div>
                        <div class="col-md-12 text-right mt-4 mb-5">
                            <a class="btn btn-danger" href="#" data-toggle="modal" data-target="#deleteProfile" @click.prevent="deleteUser">Supprimer le profil</a>
                            <!--<a class="btn btn-secondary" href="profil.html">voir le profil</a>-->
                            <a class="btn btn-primary" href="#" @click.prevent="saveData">Enregistrer les modifications</a>
                        </div>
                    </form>
                </div>

            </div>
        </div>

        <div class="container" v-if="reportings.length > 0 || (blockings.length > 0 && blockings.length > unlocked.length)">
            <h3>Historique des signalements et blocages</h3>
            <!-- Signalements -->
            <div class="banandblock" v-if="reportings.length > 0">
                <h4>Signalement(s)</h4>
                <!-- Liste des signalements -->
                <div class="banandblock-list">
                    <!-- Liste des signalements - Lables des colonnes -->
                    <div class="banandblock-list-item header">
                        <div class="list-profile">Signalé par</div>
                        <div class="list-date">Date</div>
                        <div class="list-abstract">Motif</div>
                        <div class="list-action">Action</div>
                    </div>
                    <!-- Liste des signalements - Entrées de la liste -->
                    <div class="banandblock-list-item" v-for="report in reportings" :key="report.userName">
                        <div class="list-profile">
                            <div class="list-profile-picture"><img :src="report.pictureCode" class="card-img-top"></div>
                            <div class="list-profile-body"><router-link :to="{ name: 'admin-profile-edit', params: { locale: $i18n.locale, slug: report.userSlug } }">{{ report.pseudo }}</router-link></div>
                        </div>
                        <div class="list-date">{{ $filters.date(report.interactionDate) }}</div>
                        <div class="list-abstract">{{ report.reason }}</div>
                        <div class="list-action"><span v-if="report.processDate">traité le {{ $filters.date(report.processDate) }}</span></div>
                    </div>
                </div>
            </div>

            <div class="banandblock" v-if="blockings.length > 0 && blockings.length > unlocked.length">
                <h4>Blocages(s)</h4>
                <!-- Liste des blocages -->
                <div class="banandblock-list">
                    <!-- Liste des blocages - Lables des colonnes -->
                    <div class="banandblock-list-item header">
                        <div class="list-profile">Bloqué par</div>
                        <div class="list-date">Date</div>
                        <div class="list-abstract"></div>
                    </div>
                    <!-- Liste des blocages - Entrées de la liste -->
                    <div v-for="block in blockings" :key="block.userName">
                        <div class="banandblock-list-item" v-if="!unlocked.includes(block.userName)">
                            <div class="list-profile">
                                <div class="list-profile-picture"><img :src="block.pictureCode" class="card-img-top"></div>
                                <div class="list-profile-body"><router-link :to="{ name: 'admin-profile-edit', params: { locale: $i18n.locale, slug: block.userSlug } }">{{ block.pseudo }}</router-link></div>
                            </div>
                            <div class="list-date">{{ $filters.date(block.interactionDate) }}</div>
                            <div class="list-abstract"><button class="btn btn-danger btn-sm" @click="unlock(block.userName)">Débloquer</button></div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import User from "../../components/admin/profiles/User.vue"
    import UserFields from "../../components/shared/profileEdit/Fields.vue"
    import ProfilePicture from "../../components/shared/profileEdit/ProfilePicture.vue"
    import Pictures from "../../components/shared/profileEdit/Pictures.vue"
    import axios from "axios";

    export default {
        name: "admin-profile-edit",
        components: {
            User,
            UserFields,
            ProfilePicture,
            Pictures
        },
        data() {
            return {
                profile: null,
                userData: null,
                statusList: [],
                fieldChoices: null,
                userFieldChoices: [],
                subscriptionTypes: [],
                isZipOrPwError: false,
                isError: false,
                errors: {},
                requiredFields: ['firstName', 'lastName', 'pseudo', 'email', 'status', 'gender', 'birthDate', 'zipCode'],
                history: [],
                unlocked: []
            };
        },
        methods: {
            setData(profile, statusList, fieldChoices, subscriptionTypes) {
                this.setProfile(profile)

                this.statusList = statusList;
                this.fieldChoices = fieldChoices;
                this.subscriptionTypes = subscriptionTypes;
            },
            setProfile(profile) {
                this.profile = profile;
                this.userData = profile.user;
                this.userFieldChoices = profile.userFieldChoices;
                this.getHistory(profile.user.id)
                window.scrollTo(0, 0);
            },
            setProfilePicture() {
                this.$refs.profilePict.setLocalPicture(this.profile.user.picture)
            },
            getHistory(user_id) {
                let ref = this
                axios.get(`/api/reports/history/${user_id}`)
                    .then(response => {
                        ref.history = response.data;
                    })
            },
            validateForm() {
                this.isError = false
                let i = 0
                this.errors = {};
                for (i; i < this.requiredFields.length; i++) {
                    let field = this.requiredFields[i]
                    let fieldValue = this.profile.user[field]

                    if (fieldValue == null || fieldValue.trim() == '' || fieldValue == '0') {
                        this.isError = true
                        let fieldTrans = this.$t('fields.' + field)
                        let msg = this.$t('fields.required', { field: fieldTrans })
                        this.errors[field] = msg
                    }
                }
            },
            isUnique(field) {
                return new Promise((resolve) => {
                    axios.get(`/api/profiles/${field.toLowerCase()}-exists/${this.userData[field]}/${this.userData.id}`)
                        .then(
                            response => {
                                if (response.data) {
                                    let msg = this.$t('fields.unique', { value: this.userData[field] })
                                    this.errors[field] = msg
                                    this.isError = true
                                }
                                resolve('done')
                            }
                        )
                })
            },
            saveData() {
                /* eslint-disable */
                this.validateForm()
                // email unique
                this.isUnique('email').then(
                    // username unique
                    result => this.isUnique('userName').then(
                        result => this.storeData()
                    )
                )
                /* eslint-enable */
            },
            storeData() {
                if (!this.isZipOrPwError && !this.isError) {
                    this.profile.user = this.userData;
                    /* eslint-disable */
                    axios.post("/api/adminprofiles/create-update", this.profile)
                        .then(
                            response => {
                                const locale = this.$i18n.locale
                                const status = this.userData.status
                                this.$router.replace({ name: 'admin-profiles', params: { locale }, query: { s: status } })
                            }
                        )
                        .catch(error => {
                            //TO DO: handle server side validation
                            //console.log(error.data);
                        });
                    /* eslint-enable */
                }
            },
            deleteUser() {
                if (confirm('Voulez-vous vraiment supprimer ce profil? Cette action est irréversible.')) {
                    /* eslint-disable */
                    axios.get(`/api/adminprofiles/delete/${this.userData.id}`)
                        .then(
                            response => {
                                const locale = this.$i18n.locale
                                const msg = 'del'
                                const status = this.userData.status
                                this.$router.replace({ name: 'admin-profiles', params: { locale }, query: { s: status, m: msg } })
                            }
                        )
                    /* eslint-enable */
                }
            },
            deletePictureCode(pictureCode) {
                var index = this.userData.newPictures.indexOf(pictureCode);
                if (index !== -1) {
                    this.userData.newPictures.splice(index, 1);
                }
            },
            unlock(userName) {
                axios.post('/api/adminprofiles/unlock', { senderUserName: userName, targetUserName: this.profile.user.userName })
                this.unlocked.push(userName)
            }
        },
        computed: {
            reportings() {
                return this.history.filter(h => h.interactionType_Id == 5)
            },
            blockings() {
                return this.history.filter(h => h.interactionType_Id == 4)
            }
        },
        beforeRouteEnter(to, from, next) {
            let getUserPath = '';
            if (to.params.slug != null && to.params.slug != "") {
                getUserPath = `/api/adminprofiles/edit/${to.params.slug}`;
            } else {
                getUserPath = `/api/adminprofiles/create`;
            }
            axios.all([
                axios.get(getUserPath),
                axios.get(`/api/adminprofiles/status-list`),
                axios.get(`/api/profiles/field-choices`),
                axios.get(`/api/adminprofiles/subscription-types`)
            ]).then(
                axios.spread((profile, statusList, fieldChoices, subscriptionTypes) => {
                    next(vm => vm.setData(profile.data, statusList.data, fieldChoices.data, subscriptionTypes.data));
                })
            );
        },
        beforeRouteUpdate(to, from, next) {
            axios.get(`/api/adminprofiles/edit/${to.params.slug}`)
                .then(response => {
                    this.setProfile(response.data)
                    this.setProfilePicture()
                    next()
                });
        }

    }
</script>