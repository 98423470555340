<template>
    <div class="list-item" v-for="field in fields" :key="field.slug">
        <div class="list-item-content">
            <div class="list-label">{{ $t('fields.f-' + field.field_Slug) }}</div>
            <div class="list-value" v-for="choice in field.choices" :key="choice">{{translateChoice(field.field_Slug, choice) }}{{field.field_Slug=='height'?' cm':''}}{{field.field_Slug=='weight'?' kg':''}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'fields-tab',
        props: {
            fields: {
                type: Object,
                required: true
            }
        },
        methods: {
            translateChoice(slug, choice) {
                if (this.isNumber(choice)) {
                    return choice
                } else {
                    return this.$t('fields.' + slug + '.' + choice)
                }
            },
            isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 
        },
    }
</script>