<template>
    <!--<label class="field-label">{{ $t("Photo de profil")}}</label>-->
    <div id="profile-picture-gallery">
        <div id="profile-picture-gallery-main-picture">
            <div class="picture-item">
                <img :src="locPicture" class="card-img-top" :alt="pseudo" :slug="slug" v-if="locPicture">
                <span class="default" v-else><i class="far fa-user"></i></span>
            </div>
        </div>
    </div>
    <div id="profile-picture-action-buttons" class="d-flex flex-wrap">
        <input type="file" accept="image/*"
               style="display: none"
               ref="fileInput"
               @change="uploadImage($event)"
               id="file-input">
        <a href="#" class="add-file-container" v-if="picture || newPicture" @click="onPickFile"><div class="add-file"><span>+</span></div>{{ $t("Remplacer la photo") }}<span></span></a>
        <a href="#" class="add-file-container" v-else @click="onPickFile"><div class="add-file"><span>+</span></div>{{ $t("Ajouter une photo") }} *<span></span></a>
    </div>
</template>

<script>
    //import axios from "axios";
    import optimizePhoto from "@/helpers/optimizePhoto.js"

    export default {
        name: "profile-picture",
        emits: ["input"],
        props: {
            picture: {
                type: String
            },
            newPicture: {
                type: String
            },
            pseudo: {
                type: String
            },
            slug: {
                type: String
            }
        },
        data() {
            return {
                locPicture: ''
            }
        },
        mounted() {
            if (this.newPicture == null || this.newPicture == '') {
                if (this.picture != null) {
                    this.locPicture = this.picture
                }
            } else {
                this.locPicture = this.newPicture
            }
        },
        methods: {
            setLocalPicture(picture) {
                console.log('setLocalPicture')
                this.locPicture = picture
            },
            onPickFile() {
                this.$refs.fileInput.click()
            },
            async uploadImage(e) {
                const image = e.target.files[0];
                const resizedPhoto = await optimizePhoto(image);
                // Preview image
                const reader = new FileReader();
                reader.readAsDataURL(resizedPhoto);

                let ref = this
                reader.onloadend = function () {
                    let pictureCode = reader.result;
                    ref.locPicture = pictureCode;
                    ref.$emit("input", pictureCode);
                }
            }
        }
    }
</script>