<template>
    <!-- Principe de mise en page B - Affichage texte à droite et visuel à gauche -->
    <div class="py-5 principe-B" v-if="content">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mb-5">
                    <h1>{{ content.title }}</h1>
                    <h2 v-if="content.subTitle">{{ content.subTitle }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="principe-B d-flex">
                    <!-- Colonne texte -->
                    <div class="col-md-6">
                        <div v-if="content.headline">
                            <p v-html="content.headline"></p>
                        </div>
                        <div v-if="content.buttonLabel">
                            <a href="#" class="button-link" @click.prevent="goToPage" v-if="content.buttonLink == null || content.buttonLink == ''">{{ content.buttonLabel }}</a>
                            <a :href="content.buttonLink" class="button-link" target="_blank" v-else>{{ content.buttonLabel }}</a>
                        </div>
                    </div>
                    <!-- Colonne visuel -->
                    <div class="col-md-6">
                        <div class="mb-3 principe-picture" v-if="picture">
                            <img :src="picture" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "template-b",
        props: {
            content: Object,
            picture: String
        },
        methods: {
            goToPage() {
                let action = 'page'
                if (this.content.contentPlace_Slug == 'auth-home' || this.content.contentPlace_Slug == 'events') {
                    action = 'info'
                }
                this.$router.push(`/${this.$i18n.locale}/${action}/${this.content.slug}`);
            }
        }
    }
</script>

<style>
    .principe-picture {text-align:center;}
    .principe-picture img.img-fluid {max-width:70%;}

    @media (min-width: 768px) {
        .principe-picture img.img-fluid {max-width:100%;}
    }
</style>