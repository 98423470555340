<template>
    <div class="form-group row mt-5">
        <label class="col-lg-3 field-label">{{ $t("Photos supplémentaires") }}</label>
        <div class="col-lg-9">
            <!-- Encoded pictures -->
            <div id="profile-pictures-thumbnails" class="d-flex mt-2 mb-3" v-if="userPictures">
                <div v-for="(picture, index) in userPictures" :key="index">
                    <div class="picture-item" v-if="oldPictures.indexOf(picture.id)==-1">
                        <img :src="picture.pictureCode" class="img-fluid">
                        <div class="profile-picture-delete"><a href="#" @click.prevent="deletePicture(picture.id)"><i class="far fa-trash-alt"></i></a></div><div class="profile-picture-status"></div>
                    </div>
                </div>
            </div>
            <!-- New pictures -->
            <div id="profile-pictures-thumbnails" class="d-flex mt-2 mb-3" v-if="newPictures">
                <div class="picture-item" v-for="(picture, index) in newPictures" :key="index">
                    <img :src="picture.pictureCode" class="img-fluid">
                    <div class="profile-picture-delete"><a href="#" @click.prevent="deleteNewPicture(index)"><i class="far fa-trash-alt"></i></a></div><div class="profile-picture-status"></div>
                </div>
            </div>

            <!-- button -->
            <input type="file" accept="image/*"
                   style="display: none"
                   ref="fileInput"
                   @change="uploadImages($event)"
                   id="file-input"
                   multiple="multiple">
            <a href="#" class="add-file-container" @click.prevent="onPickFile"><div class="add-file"><span>+</span></div>{{ $t("Ajouter une photo") }}</a>
        </div>
    </div>
</template>

<script>
    //import axios from "axios";
    import optimizePhoto from "@/helpers/optimizePhoto.js"

    export default {
        name: "pictures",
        emits: ["delete", "add", "delete-picture-code"],
        props: {
            userPictures: {
                type: Array
            },
            slug: {
                type: String
            }
        },
        data() {
            return {
                newPictures: [],
                oldPictures: []
            }
        },
        methods: {
            deletePicture(id) {
                console.log(id)
                this.oldPictures.push(id)
                this.$emit("delete", this.oldPictures);
            },
            deleteNewPicture(index) {
                const oldPictureCode = this.newPictures[index].pictureCode
                //console.log(oldPictureCode)
                this.newPictures.splice(index, 1)
                this.$emit("delete-picture-code", oldPictureCode);
            },
            onPickFile() {
                this.$refs.fileInput.click()
            },
            async uploadImages(e) {
                let i = 0
                for (i = 0; i < e.target.files.length; i++) {
                    const image = e.target.files[i];
                    const resizedPhoto = await optimizePhoto(image);

                    //let pictureCode
                    // Preview image
                    const reader = new FileReader();
                    //let pictureCode = ''
                    reader.readAsDataURL(resizedPhoto);
                    //reader.onload = e => {
                    //    pictureCode = e.target.result;
                    //};
                    let ref = this
                    reader.onloadend = function () {
                        let pictureCode = reader.result;
                        let newPic = { name: image.name, pictureCode: pictureCode }
                        ref.newPictures.push(newPic)
                        ref.$emit("add", ref.newPictures.map(({ pictureCode }) => pictureCode));
                    }


                    // Upload image
                    //let data = new FormData();
                    //data.append('name', image.name);
                    //data.append('file', resizedPhoto);

                    //let config = {
                    //    header: {
                    //        'Content-Type': 'image/png'
                    //    }
                    //}

                    //axios.post(
                    //    '/api/profiles/picture-upload',
                    //    data,
                    //    config
                    //).then(
                    //    response => {
                    //        console.log('image upload response > ', response)
                    //        if (response.statusText == 'OK') {
                    //            let newPic = { name: response.data, pictureCode: pictureCode }
                    //            this.newPictures.push(newPic)

                    //            this.$emit("add", this.newPictures.map(({ name }) => name));
                    //        }
                    //    }
                    //)
                }

            }
        }
    }
</script>