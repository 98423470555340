import { createStore } from 'vuex'

import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

//const store = new Vuex.Store({
//    strict: true,
//    actions,
//    mutations,
//    getters,
//    state: {
//        auth: null,
//        showAuthModal: false,
//        loading: false,
//        cart: []
//    }
//});



//export default store;

export const store = createStore({
    actions,
    mutations,
    getters,
    state() {
        return {
            auth: null,
            loading: false,
            connection: null,
            isBackoffice: false,
            visitCount: 0,
            showVisits: false,
            likeCount: 0,
            showLikes: false,
            messageCount: 0,
            showMessages: false,
            closeToExpiration: false
            //status: 'Inscrit'
        }
    }
})


//export default store

