<template>
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "subscription-success",
        methods: {
            redirect(route) {
                /* eslint-disable */
                // change user role
                this.$store.dispatch('subscribe').then(
                    response => {
                        // hide subscription end alert
                        this.$store.dispatch("isCloseToExpiration", false)
                        // redirection
                        this.$router.push({
                            name: route,
                            params: { locale: this.$i18n.locale },
                            query: { subscrok: 1 }
                        })
                    }
                )
                /* eslint-enable */
            }
        },
        beforeRouteEnter(to, from, next) {
            console.log('chibouli')
            axios.get(`/api/subscription/subscription-success/${to.params.token}`)
                .then(response => {
                    next(vm => vm.redirect(response.data))
                });
        },

    }
</script>