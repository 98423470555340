<template>
    <main role="main" v-if="content">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-2 mb-3">
                    <a href="#" class="back-link" @click.prevent="goBack"><span><i class="fas fa-arrow-left" aria-hidden="true"></i></span>{{ $t('Retour') }}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-5 text-left">
                    <h1>Edition d'un bloc {{ blocName }} - {{ templateName }}</h1>
                </div>
            </div>

            <div class="row">
                <!-- Colonne de gauche  -->
                <div class="col-md-3">
                    <!-- Mise en page -->
                    <div class="form-group">
                        <label for="selectBlockPrincipe" class="field-label">Mise en page</label>
                        <select class="form-control" id="selectBlockPrincipe" v-model="content.contentTemplate_Id" :disabled="content.contentTemplate_Id==6">
                            <option v-for="template in templateList" :key="template.id" :value="template.id">{{ template.name }}</option>
                        </select>
                    </div>
                    <!-- Destination -->
                    <div class="form-group" >
                        <label for="selectBlockDestination" class="field-label">Destination</label>
                        <select class="form-control" id="selectBlockDestination" v-model="content.contentPlace_Id" :disabled="content.contentTemplate_Id==6">
                            <option v-for="place in places" :key="place.id" :value="place.id">{{ place.name }}</option>
                        </select>
                    </div>
                    <!--<div class="form-group">
                        <label for="BlockKey" class="field-label">Clé</label>
                        <input type="text" id="BlockKey" class="form-control">
                    </div>-->
                    <div class="form-group">
                        <label for="BlockOnline" class="field-label">Publication</label>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="BlockOnline" value="1" v-model="content.isPublished">
                            <label class="custom-control-label" for="BlockOnline">Ce bloc est publié</label>
                        </div>
                    </div>
                </div>
                <!-- END Colonne de gauche  -->

                <!-- Partie centrale -->
                <div class="col-md-9">
                    <form>
                        <div class="card" v-if="content.contentTemplate_Id!=3">
                            <div class="card-header d-flex">
                                <div class="block-item-label">{{ helper }}</div>
                            </div>
                            <div class="card-body">

                                <!-- Photo -->
                                <content-picture :picture="content.picture" @input="content.newPicture = $event"></content-picture>
                                <!-- Catégorie (événement)-->
                                <div class="form-group row" v-if="content.contentTemplate_Id==5">
                                    <label for="category" class="col-lg-3 field-label">Catégorie (picto)</label>
                                    <div class="col-lg-9">
                                        <select class="form-control" id="category" v-model="content.contentCategory_Slug">
                                            <option :value="null">Sélectionnez la catégorie</option>
                                            <option v-for="category in data.contentCategories" :key="category.slug" :value="category.slug">{{ category.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Date de début (événement)-->
                                <div class="form-group row" v-if="content.contentTemplate_Id==5">
                                    <label for="startDate" class="col-lg-3 field-label">Date de début</label>
                                    <div class="col-lg-9">
                                        <date-field :minYearFromToday="-10" :maxYearFromToday="10" :selectedDate="content.eventStartDate" @update="content.eventStartDate = $event"></date-field>
                                    </div>
                                </div>
                                <!-- Date de fin (événement)-->
                                <div class="form-group row" v-if="content.contentTemplate_Id==5">
                                    <label for="endDate" class="col-lg-3 field-label">Date de fin</label>
                                    <div class="col-lg-9">
                                        <date-field id="endDate" :minYearFromToday="-10" :maxYearFromToday="10" :selectedDate="content.eventEndDate" @update="content.eventEndDate = $event"></date-field>
                                    </div>
                                </div>
                                <!-- Prix (événement)-->
                                <div class="form-group row" v-if="content.contentTemplate_Id==5">
                                    <label for="price" class="col-lg-3 field-label">Informations de prix</label>
                                    <div class="col-lg-9">
                                        <input type="text" id="price" class="form-control" v-model="content.eventPrice">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else><h2 class="mb-3">{{ helper }}</h2></div>

                        <!-- Edition du bloc -->
                        <div id="accordion" class="language-selector mt-3">
                            <!-- Français -->
                            <content-translation :language="'francophones'" :template="content.contentTemplate_Slug" :translation="getTranslation('fr')" :isOpen="true"></content-translation>
                            <!-- Néerlandais -->
                            <content-translation :language="'néerlandophones'" :template="content.contentTemplate_Slug" :translation="getTranslation('nl')"></content-translation>
                            <!-- Anglais -->
                            <content-translation :language="'anglophones'" :template="content.contentTemplate_Slug" :translation="getTranslation('en')"></content-translation>
                        </div>
                        <!-- END Edition du bloc -->

                        <!-- Liste des sous-contenus -->
                        <div v-if="content.contentTemplate_Id==3 && content.Id !=0">
                            <div class="text-left mt-3 mb-3">
                                <h2>Sous-contenus (colonnes)</h2>
                            </div>
                            <!-- Liste des Blocks -->
                            <div class="mb-5">
                                <div class="row">
                                    <content-list :contents="content.subContents"></content-list>

                                    <div class="col-md-12 text-right mt-2" v-if="content.subContents != null && content.subContents.length < 4">
                                        <a class="btn btn-secondary" href="#" @click.prevent="createSubContent">Ajouter un bloc colonne - Principe F (max 4)</a>
                                    </div>
                                </div>
                            </div>
                            <!-- END Liste des Blocks -->
                        </div>
                        <!-- END Liste des sous-contenus -->

                        <!-- Message erreur -->
                        <div class="alert alert-danger" role="alert" v-if="isError">
                            {{ $t('Veuillez vérifier la validité du formulaire') }}
                            <ul v-if="errors">
                                <li v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                        </div>
                        <!-- Bouton -->
                        <div class="text-right mt-4 mb-5">
                            <a class="btn btn-primary" href="#" @click.prevent="saveData">Enregistrer</a>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import ContentPicture from "@/components/admin/content/ContentPict"
    import ContentTranslation from "@/components/admin/content/ContentTrans"
    import DateField from "@/components/shared/fields/DateField"
    import ContentList from "@/components/admin/content/ContentList"
    import axios from "axios";

    export default {
        name: "content-edit",
        components: {
            ContentTranslation,
            ContentPicture,
            DateField,
            ContentList
        },
        data() {
            return {
                data: null,
                templates: [],
                places: [],
                content: null,
                isError: false,
                errors: {},
                requiredTransFields: ['title'],
                languages: ['fr', 'nl', 'en']
            }
        },
        computed: {
            blocName() {
                if (this.content != null) {
                    return (this.templates.filter(x => x.id == this.content.contentTemplate_Id)[0].description)
                }
                return ''
            },
            templateName() {
                if (this.content != null) {
                    return (this.templates.filter(x => x.id == this.content.contentTemplate_Id)[0].name)
                }
                return ''
            },
            templateList() {
                if (this.content != null) {
                    if (this.content.contentTemplate_Id == 6) {
                        return (this.templates.filter(x => x.id == 6))
                    } else {
                        return (this.templates.filter(x => x.id != 6))
                    }
                }
                return ''
            },
            helper() {
                if (this.content != null) {
                    return (this.templates.filter(x => x.id == this.content.contentTemplate_Id)[0].helper)
                }
                return ''
            },
        },
        methods: {
            setData(data) {
                this.data = data
                this.templates = this.data.contentTemplates
                this.places = this.data.places
                this.content = this.data.contentEdit
                if (this.$route.query.parent != undefined) {
                    this.content.parent_Id = this.$route.query.parent
                }
            },
            getTranslation(language_id) {
                if (this.content != null) {
                    return (this.content.translations.filter(x => x.language_Id == language_id)[0])
                }
                return null
            },
            validateForm() {
                this.isError = false
                let i = 0
                this.errors = {};
                for (i; i < this.requiredTransFields.length; i++) {
                    let field = this.requiredTransFields[i]
                    let j = 0
                    for (j; j < 3; j++) {
                        let fieldValue = this.content.translations[j][field]
                        if (fieldValue == null || fieldValue.trim() == '' || fieldValue == '0') {
                            this.isError = true
                            console.error("erreur sur " + field)
                            let fieldTrans = '"' + this.$t('fields.' + field) + '"'
                            fieldTrans += ' ' + this.languages[j].toUpperCase()
                            let msg = this.$t('fields.required', { field: fieldTrans })
                            this.errors[field + '_' + this.languages[j]] = msg
                        }
                    }
                }
            },
            saveData() {
                // TO DO : form validation
                this.validateForm()
                if (!this.isError) {
                    /* eslint-disable */
                    axios.post("/api/admincontents/create-update", this.content)
                        .then(
                            response => {
                                const locale = this.$i18n.locale
                                const placeSlug = this.places.filter(x => x.id == this.content.contentPlace_Id)[0].slug
                                if (this.content.parent_Id != 0) {
                                    this.$router.replace({ name: 'admin-content-edit', params: { locale, id: this.content.parent_Id } })
                                } else {
                                    this.$router.replace({ name: 'admin-contents', params: { locale, slug: placeSlug } })
                                }

                            }
                        )
                        .catch(error => {
                            //TO DO: handle server side validation
                            console.log(error.data);
                        });
                    /* eslint-enable */
                }
            },
            createSubContent() {
                this.$router.push(`/${this.$i18n.locale}/admin/content-create?t=6&p=${this.content.contentPlace_Id}&parent=${this.content.id}`);
                window.scrollTo(0, 0);
            },
            goBack() {
                if (this.content.parent_Id != 0) {
                    const locale = this.$i18n.locale
                    this.$router.replace({ name: 'admin-content-edit', params: { locale, id: this.content.parent_Id } })
                } else {
                    this.$router.go(-1)
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            let url = `/api/admincontents/edit/${to.params.id}`

            if (to.name == 'admin-content-create') {
                url = `/api/admincontents/create/${to.query.t}/${to.query.p}`
            }
            axios.get(url)
                .then(response => {
                    next(vm => vm.setData(response.data));
                });
        },
        beforeRouteUpdate(to, from, next) {
            axios.get(`/api/admincontents/edit/${to.params.id}`)
                .then(response => {
                    this.setData(response.data);
                    window.scrollTo(0, 0);
                    next();
                });
        }
    }
</script>