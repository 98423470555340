<template>
    <div id="profile-settings">
        <div class="container">
            <div class="row">
                <div id="profile-settings-inner">
                    <div id="search-filters-settings-inner" class="mt-3">
                        <nav id="search-filters-settings-nav" class="nav nav-pills">
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='physical'}" href="#" @click.prevent="setTab('physical')">
                                <div class="search-nav-logo" id="tab-physical"><img src="../../../../public/images/picto-tab-physique.svg" /></div><div class="search-nav-label">{{ $t('physique') }}</div>
                            </a>
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='emotional'}" href="#" @click.prevent="setTab('emotional')">
                                <div class="search-nav-logo" id="tab-emotional"><img src="../../../../public/images/picto-tab-emotionnel.svg" /></div><div class="search-nav-label">{{ $t('personnalité') }}</div>
                            </a>
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='mental'}" href="#"  @click.prevent="setTab('mental')">
                                <div class="search-nav-logo" id="tab-mental"><img src="../../../../public/images/picto-tab-mental.svg" /></div><div class="search-nav-label">{{ $t('mental') }}</div>
                            </a>
                            <a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='spiritual'}" href="#"  @click.prevent="setTab('spiritual')">
                                <div class="search-nav-logo" id="tab-spirit"><img src="../../../../public/images/picto-tab-spiritualite.svg" /></div><div class="search-nav-label">{{ $t('spiritualité') }}</div>
                            </a>
                        </nav>
                        <div id="search-filters-settings-forms" class="tab-content">
                            <div :class="{'tab-pane':1,active: activeTab==='physical'}" id="physical" v-if="activeTab==='physical'">

                                <physical :fields="physicalFields" :userChoices="physicalUserChoices"></physical>

                            </div>
                            <div :class="{'tab-pane':1,active: activeTab==='emotional'}" id="emotional" v-if="activeTab==='emotional'">

                                <emotional :fields="emotionalFields" :userChoices="emotionalUserChoices"></emotional>

                            </div>
                            <div :class="{'tab-pane':1,active: activeTab==='mental'}" id="mental" v-if="activeTab==='mental'">

                                <mental :fields="mentalFields" :userChoices="mentalUserChoices"></mental>

                            </div>
                            <div :class="{'tab-pane':1,active: activeTab==='spiritual'}" id="spiritual" v-if="activeTab==='spiritual'">

                                <spiritual :fields="spiritualFields" :userChoices="spiritualUserChoices"></spiritual>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Physical from "./FieldsPhysical.vue"
    import Emotional from "./FieldsEmotional.vue"
    import Mental from "./FieldsMental.vue"
    import Spiritual from "./FieldsSpiritual.vue"

    export default {
        name: "profile-fields",
        components: {
            Physical,
            Emotional,
            Mental,
            Spiritual
        },
        props: {
            fieldChoices: {
                type: Object,
                required: true
            },
            userFieldChoices: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                activeTab: 'physical',
            }
        },
        computed: {
            physicalFields() {
                return this.fieldChoices.filter(c => c.category==='physical')[0].fields
            },
            physicalUserChoices() {
                return this.userFieldChoices.filter(c => c.category === 'physical')
            },

            emotionalFields() {
                return this.fieldChoices.filter(c => c.category === 'emotional')[0].fields
            },
            emotionalUserChoices() {
                return this.userFieldChoices.filter(c => c.category === 'emotional')
            },

            mentalFields() {
                return this.fieldChoices.filter(c => c.category === 'mental')[0].fields
            },
            mentalUserChoices() {
                return this.userFieldChoices.filter(c => c.category === 'mental')
            },

            spiritualFields() {
                return this.fieldChoices.filter(c => c.category === 'spiritual')[0].fields
            },
            spiritualUserChoices() {
                return this.userFieldChoices.filter(c => c.category === 'spiritual')
            },
        },
        methods: {
            setTab(tab) {
                this.activeTab = tab
            }
        }
    }
</script>