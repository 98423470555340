<template>
    <div contenteditable="true" @keyup="updateHTML" id="textarea"></div>
</template>

<script>
    export default {
        name: 'html-textarea',
        emits: ['input', 'send'],
        data() {
            return {
                locMsg: ''
            }
        },
        methods: {
            updateHTML(e) {
                if ((e.keyCode == 10) || (e.keyCode === 13 && (e.metaKey || e.ctrlKey || e.shiftKey))) {
                    this.locMsg = e.target.innerHTML + '<br>'
                    this.$emit('input', this.locMsg);
                } else if (e.keyCode === 13) {
                    e.preventDefault()
                    this.$emit('send');
                } else {
                    this.locMsg = e.target.innerHTML
                    this.$emit('input', this.locMsg);
                }
            },
            addSmiley(s) {
                this.locMsg += s
                document.querySelector("#textarea").innerHTML = this.locMsg
                this.$emit('input', this.locMsg);
            },
            clear() {
                this.locMsg = ''
                document.querySelector("#textarea").innerHTML = this.locMsg
            },
        }
    }
</script>

<style>
    div[contenteditable] {
        border: 1px solid lightblue;
        border-radius: 15px;
        padding: 10px;
    }
</style>