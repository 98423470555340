<template>
    <main role="main">
        <div class="container">

            <div class="row">
                <div class="col-md-12 text-center mt-5 mb-4">
                    <h1>Contenus</h1>
                </div>
            </div>

            <!-- Liste des pages de contenus -->
            <div class="mt-5 mb-5">
                <div class="row">
                    <div class="col-md-12"><h2>Destinations</h2></div>
                    <div class="col-md-12 content-list" v-if="contentPlaces">
                        <div class="content-list-item" v-for="(place, index) in contentPlaces" :key="index">
                            <div class="content-list-item-name"><a href="#" @click.prevent="viewContents(place.slug)">{{ place.name }}</a></div>
                            <div class="content-list-item-buttons"><a href="#" @click.prevent="viewContents(place.slug)"><i class="far fa-edit"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END liste des pages de contenus -->

        </div>
    </main>
</template>

<script>
    import axios from "axios";

    export default {
        name: "content-places",
        data() {
            return {
                contentPlaces: [],
                locale: 'fr'
            }
        },
        methods: {
            setData(contentPlaces) {
                this.contentPlaces = contentPlaces
            },
            viewContents(place) {
                this.$router.push(`/${this.$i18n.locale}/admin/contents/${place}`);
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.get(`/api/admincontents/content-places`)
            .then(response => {
                next(vm => vm.setData(response.data));
            });
        }
    }
</script>