<template>
    <div class="flex-shrink-0 account-form-container">

        <!-- Formulaire d'identification -->
        <form @submit.prevent="login" class="form-signin">
            <h1 class="h3 mb-3 font-weight-normal">{{ $t("Se connecter") }}</h1>
            <div class="form-group">
                <label for="Code">{{ $t("Pour accéder au site, entrez le code reçu par e-mail") }}:</label>
                <input type="text" v-model.trim="code" id="Code" class="form-control" required v-model="code">
            </div>

            <div class="alert alert-danger" role="alert" v-if="error">
                {{ error }}
            </div>
            <button class="btn btn-lg button-link btn-block" type="submit" @click.prevent="checkCode">{{ $t("Se connecter") }}</button>
            <div >
                <a href="javascript:history.back()" class="back-link"><span><i class="fas fa-arrow-left"></i></span>{{ $t('Retour') }}</a>
            </div>
        </form>
        
    </div>
</template>

<script>
    //import axios from "axios";

    export default {
        name: 'account-login-code',
        data() {
            return {
                email: '',
                code: '',
                error: ''
            }
        },
        mounted() {
            this.email = this.$route.query.email
        },
        methods: {
            checkCode() {
                if (this.code.trim() != '') {
                    const payload = {
                        email: this.email,
                        password: this.code
                    };
                    
                    this.$store
                        .dispatch("loginDoubleAuth", payload)
                        // eslint-disable-next-line no-unused-vars
                        .then(response => {
                            // obtenir les interactions
                            if (!this.$store.getters.isInRole('Registered')) {
                                this.$store.dispatch("getInteractionCounts")
                            }

                            if (this.$route.query.redirect) {
                                this.$router.push(this.$route.query.redirect);
                            } else {
                                const locale = this.$i18n.locale

                                if (this.$store.getters.isInRole('IncompleteSubscriber') || this.$store.getters.isInRole('IncompleteGuest')) {
                                    // Incomplete profile
                                    this.$router.push({ name: 'profile-edit', params: { locale }, query: { subscrok: 1 } })
                                } else {
                                    let url = 'home-connected'
                                    if (this.$store.getters.isInRole('Registered')) {
                                        // subscription page
                                        url = 'subscription'
                                    }
                                    this.$router.push({ name: url, params: { locale } })
                                }
                            }
                        })
                        .catch(error => {
                            console.log("code check error:" + error.data)
                            this.error = this.$t("Le code n'est pas valide");
                        });
                    //axios.get(`/api/check-code/${this.code}`)
                    //    .then(response => {
                    //        if (response.data == "valid") {
                    //            // login
                    //            const payload = {
                    //                email: this.email,
                    //                password: this.password
                    //            };
                    //        } else {
                    //            this.showError = true
                    //        }
                    //    })
                }
            }
        }
    }
</script>