<template>
    <div class="grid-item">
        <div class="card" v-if="content">
            <div class="card-picture" v-if="picture"><img :src="picture" class="card-img-top"><div :class="[{'event-category':true},content.contentCategory_Slug]" v-if="content.contentCategory_Slug"><i class="fas fa-music"></i></div></div>
            <div class="card-body">
                <div class="card-body-row">
                    <div class="card-body-left">
                        <h2 class="card-title">{{ content.title }}</h2>
                        <h3 v-if="content.subTitle">{{ content.subTitle }}</h3>
                        <p class="card-text" v-if="content.headline" v-html="content.headline"></p>
                    </div>
                    <div class="card-body-right" v-if="content.eventStartDate">
                        {{ $filters.date(content.eventStartDate) }}<span v-if="content.eventEndDate && $filters.date(content.eventStartDate) != $filters.date(content.eventEndDate)"> - {{ $filters.date(content.eventEndDate) }}</span>
                    </div>
                </div>
                <div class="card-body-row">
                    <div class="card-body-left" v-if="content.eventPrice">
                        <p class="small">{{ content.eventPrice }}</p>
                    </div>
                    <div class="card-body-right" v-if="content.buttonLabel">
                        <a href="#" class="button-link" @click.prevent="goToPage" v-if="content.buttonLink == null || content.buttonLink == ''">{{ content.buttonLabel }}</a>
                        <a :href="content.buttonLink" class="button-link" target="_blank" v-else>{{ content.buttonLabel }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "template-e",
        props: {
            content: Object,
            picture: String
        },
        methods: {
            goToPage() {
                let action = 'page'
                if (this.content.contentPlace_Slug == 'auth-home' || this.content.contentPlace_Slug == 'events') {
                    action = 'info'
                }
                console.log(`/${this.$i18n.locale}/${action}/${this.content.slug}`)
                this.$router.push(`/${this.$i18n.locale}/${action}/${this.content.slug}`);
            }
        }
    }
</script>