<template>
    <div class="multi-select">
        <input type="text"
               :value="text"
               :name="name"
               :class="inputTextClass" 
               @click="focus" 
               @blur="blur"
               @keydown.prevent="keydown"
               autocomplete="off"
               readonly="readonly"
        />
        <ul class="list-group" v-if="open">
            <li class="list-group-item" v-for="(item, index) in items"
                :key="index"
                @mousedown.prevent="check(item)">
                <div class="custom-checkbox">
                    <input type="checkbox" :checked="isChecked(item)" class="custom-control-input" @click.prevent="" />
                    <label class="custom-control-label">{{ $t('fields.' + name + '.' + item.slug) }}</label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

    export default {
        name: "multi-select",
        props: {
            items: {
                type: Array,
                required: true
            },
            selected: {
                type: Array
            },
            name: {
                type: String
            },
            classes: {
                type: String
            }
        },
        data() {
            return {
                open: false,
                //locSelected: []
            };
        },
        //mounted() {
        //    this.locSelected = this.selected;
        //},
        computed: {
            locSelected: {
                get() { return this.selected },
                set(locSelected) { this.$emit('input', locSelected) }
            },
            inputTextClass() {
                let openClass = ''
                if (this.open) {
                    openClass = ' open'
                }
                if (this.classes === undefined) {
                    return "form-control" + openClass
                } else {
                    return this.classes + openClass
                }
            },
            text() {
                let i = 0
                let names = []
                for (i; i < this.locSelected.length; i++) {
                    let item = this.items.filter(it => it.id == this.locSelected[i])
                    if (item != null) {
                        let translated = this.$t('fields.' + this.name + '.' + item[0].slug)
                        names.push(translated);
                    }
                }
                return names.join(", ");
            }
        },
        methods: {
            isChecked(item) {
                return this.locSelected.some(s => s === item.id);
                
            },
            focus() {
                this.open = !this.open;
                this.$emit("focus");
            },
            blur() {
                this.open = false;
                this.$emit("blur");
            },
            keydown(event) {
                event.preventDefault();
            },
            check(item) {
                var current = this.locSelected;
                var index = current.indexOf(item.id);

                if (index > -1) {
                    current.splice(index, 1);
                } else {
                    current.push(item.id);
                }

                //this.$emit("input", current);
                //this.$refs.input.$el.focus();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .multi-select {
        position: relative;
        width: 100% !important;
        .list-group {
            position: absolute;
            background-color: rgba(243, 127, 34, 1);
            top: 30px;
            right: 0;
            left: 0;
            z-index: 5;
            -webkit-box-shadow: 0 0 0 4px rgba(168,1,35,0.4);
            -moz-box-shadow: 0 0 0 4px rgba(168,1,35,0.4);
            box-shadow: 0 0 0 4px rgba(168,1,35,0.4);
            outline: none; 
            margin-bottom: 20px;
            max-height: 190px;
            overflow-y:auto;
            overflow-x:hidden;
            .list-group-item {
                cursor: pointer;
                &:hover
                {
                    background: rgb(168,1,35);
                }
                .custom-control {
                    cursor: pointer;
                }
            }
        }
    }

    .multi-select ul.list-group li {
        background-color: rgba(243, 127, 34, 1) !important;
        color:#fff;
        border: 0;
        padding: 2px 2px 2px 26px;
        box-shadow: none;
        font-size: .9em;
    }

    .multi-select ul.list-group li:hover {
        background-color: rgb(168,1,35) !important;
    }

    .multi-select label.custom-control-label{
        font-size: .9em!important;
    }

</style>
