<template>
    <div>
        <!-- Tagline et CTA de la page d'acceuil -->
        <section class="text-center" id="home-splash-header">
            <div class="container">
                <h1 v-html="$t('slogan')"></h1>
                <p><router-link :to="{ name: 'register', params: { locale } }" class="button-link light my-2">{{ $t("S'inscrire") }}</router-link></p>
            </div>
        </section>

        <main role="main">
            <div v-if="data">
                <content-display v-for="content in data" :key="content.id" :content="content"></content-display>
            </div>
        </main>
    </div>
</template>

<script>
    import axios from "axios";
    import ContentDisplay from "../components/shared/content/ContentDisplay.vue"

    export default {
        name: "homepage",
        components: {
            ContentDisplay
        },
        data() {
            return {
                
                data: [],
            }
        },
        computed: {
            locale() { return this.$i18n.locale }
        },
        methods: {
            setData(data) {
                this.data = data.value;
            },
        },
        // Chargement initial
        beforeRouteEnter(to, from, next) {
            axios.get(`/api/contents/place-contents/${to.params.locale}`)
                .then(response => {
                    next(vm => vm.setData(response.data));
                });
        },
        // Rafraîchir au changement de langue
		beforeRouteUpdate(to, from, next) {
            axios.get(`/api/contents/place-contents/${to.params.locale}`)
                .then(response => {
                    this.setData(response.data);
                    next();
                });
        }
    };
</script>

<style lang="scss">
    iframe {
        width: 300px;
        margin: 0 auto;
        display: block;
        border-style: none;
    }
</style>