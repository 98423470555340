<template>
    <div class="grid-item" v-if="profile">
        <div class="card showpointer">
            <div class="online" v-if="profile.isOnline"><img src="../../../public/images/picto-online.svg" alt="Online" /></div>
            <div class="card-picture">
                <img :src="profile.pictureCode" class="card-img-top" :alt="profile.pseudo" @click="view(profile)">
            </div>
            <div class="card-body">
                <h5 class="card-title showpointer stretched-link" @click="view(profile)">{{ profile.pseudo }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "profile-bloc",
        props: {
            profile: {
                type: Object,
                required: true
            }
        },
        methods: {
            view() {
                this.$router.push(`/${this.$i18n.locale}/profiles/${this.profile.slug}`);
            }
        }
    };
</script>
