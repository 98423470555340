<template>
    <nav-menu></nav-menu>

    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>

    <footer-vue></footer-vue>
</template>
<!--<script src="https://js.stripe.com/v3/"></script>-->
<script>
    import NavMenu from './components/shared/NavMenu.vue'
    import FooterVue from './components/shared/Footer.vue'

    export default {
        name: 'App',
        components: {
            NavMenu,
            FooterVue
        }
    }
</script>

<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s ease-in-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
</style>
