<template>
    <footer class="mt-auto">
        <div class="container">

            <div id="footer-legal">
                <div class="row">
                    <div class="col-md-12">
                        <p class="big">&copy; Mea Sensus 2021</p>
                        <ul class="align-items-start d-flex">
                            <li><router-link :to="{ name: 'page', params: { 'locale': $i18n.locale, 'slug': 'informations-legales' } }">{{ $t("Informations légales") }}</router-link></li>
                            <li><router-link :to="{ name: 'page', params: { 'locale': $i18n.locale, 'slug': 'charte-de-bonne-conduite' } }">{{ $t("Charte de bonne conduite") }}</router-link></li>
                            <li><router-link :to="{ name: 'page', params: { 'locale': $i18n.locale, 'slug': 'conditions-generales' } }">{{ $t("Conditions générales") }}</router-link></li>
                            <li><router-link :to="{ name: 'page', params: { 'locale': $i18n.locale, 'slug': 'cookies' } }">Cookies</router-link></li>
                        </ul>
                        <p>CAT-ONE SRL 0753.558.554 - <a href="mailto:info@measensus.be">info@measensus.be</a></p>
                    </div>
                </div>
            </div>

        </div>
    </footer>
</template>
<style lang="scss" scoped>
    p {font-size: .8rem;}
    p.big {font-size: 1rem;}
</style>