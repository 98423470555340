<template>
    <div id="chatroom-discussion">
        <!-- Header -->
        <div id="chatroom-discussion-header" class="d-flex">
            <div class="chatroom-discussion-header-picture"><img :src="conversation.pictureCode" v-if="conversation.pictureCode"></div>
            <div class="chatroom-discussion-header-pseudo"><h2><a href="#" @click.prevent="profileView(conversation.targetUserSlug)">{{ conversation.targetPseudo }}</a></h2></div>
        </div>
        
        <!-- Liste de messages -->
        <div id="chatroom-discussion-flow" v-if="conversation">
            <message v-for="(message, index) in conversation.messages" :key="index" :message="message"
                     :prevDate="(conversation.messages[index-1]!== undefined)?conversation.messages[index-1].messageDate:''"
                     :prevSender="(conversation.messages[index-1]!== undefined)?conversation.messages[index-1].userName:''"
                     :targetUserName="conversation.targetUserName"
                     :picture="conversation.pictureCode">
            </message>
            <span id="conversation-end"></span>
        </div>

        <!-- Smileys -->
        <transition name="slide">
            <div id="chatroom-discussion-text-smileys-grid" v-if="showSmileys">
                <a href="#" @click.prevent="insertSmiley(smiley)" v-for="smiley in smileys" :key="smiley"><span class="smiley-item" v-html="smiley"></span></a>
            </div>
        </transition>


        <div id="chatroom-discussion-text-editor">
            <!-- Encodage du message -->
            <html-textarea @input="message=$event" @send="newMessage" ref="txt"></html-textarea>

            <!-- Boutons -->
            <div id="chat-buttons-bar">
                <!-- Send image -->
                <input type="file" accept="image/*"
                       style="display: none"
                       ref="fileInput"
                       @change="sendImage($event)"
                       id="file-input">
                <a href="#" class="chat-button tooltips" @click="onPickFile">
                    <span class="tooltiptexts">{{ $t('Lorsque vous sélectionnez une image, elle sera directement envoyée.') }}</span>
                    <i class="far fa-image"></i>
                </a>
                <!-- Smileys -->
                <a href="#" class="chat-button" @click.prevent="showSmileys=!showSmileys">
                    <i class="far fa-smile" v-if="!showSmileys"></i>
                    <i class="far fa-times-circle" aria-hidden="true" v-else></i>
                </a>

                <a href="#" class="chat-button"><i class="far fa-paper-plane" @click.prevent="newMessage"></i></a>
            </div>
        </div>

    </div>
</template>

<script>
    import Message from "@/components/chat/Message"
    import HtmlTextarea from "@/components/chat/HtmlTextarea"
    import optimizePhoto from "@/helpers/optimizePhoto.js"

    export default {
        name: 'conversation',
        emits: ["new-message", "load-more"],
        components: {
            Message,
            HtmlTextarea
        },
        props: {
            conversation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                message: '',
                showSmileys: false,
                prevHeight: 0,
                allowLoadMore: true,
                smileys: [
                    "&#128512;", "&#128513;", "&#128514;", "&#128515;", "&#128516;", "&#128517;", "&#128518;", "&#128519;", "&#128520;", "&#128521;", "&#128522;", "&#128523;", "&#128524;", "&#128525;", "&#128526;", "&#128527;", "&#128528;", "&#128529;", "&#128530;", "&#128531;", "&#128532;", "&#128533;", "&#128534;", "&#128535;", "&#128536;", "&#128537;", "&#128538;", "&#128539;", "&#128540;", "&#128541;", "&#128542;", "&#128543;", "&#128544;", "&#128545;", "&#128546;", "&#128547;", "&#128548;", "&#128549;", "&#128550;", "&#128551;", "&#128552;", "&#128553;", "&#128554;", "&#128555;", "&#128556;", "&#128557;", "&#128558;", "&#128559;", "&#128560;", "&#128561;", "&#128562;", "&#128563;", "&#128564;", "&#128565;", "&#128566;", "&#128567;", "&#128577;", "&#128578;", "&#128579;", "&#128580;", "&#129296;", "&#129297;", "&#129298;",
                    "&#128147;", "&#128148;", "&#128149;", "&#128150;", "&#128151;", "&#128152;", 
                    "&#127774;", "&#128075;", "&#128076;", "&#128077;", "&#128078;", "&#128079;"
                ]
            }
        },
        mounted() {
            this.scrollToEnd()
            this.scroll();
        },
        computed: {

        },

        methods: {
            onPickFile() {
                this.$refs.fileInput.click()
            },
            async sendImage(e) {
                const image = e.target.files[0];
                const resizedPhoto = await optimizePhoto(image);
                // Preview image
                let ref = this
                var reader = new FileReader();
                reader.readAsDataURL(resizedPhoto);
                reader.onloadend = function () {
                    var pictureCode = reader.result;
                    ref.$emit('new-message', pictureCode)
                }
            },
            scroll() {
                var chatbox = document.getElementById('chatroom-discussion-flow');
                chatbox.onscroll = () => {
                    if (this.conversation.isMore) {
                        let pos = chatbox.scrollTop

                        if (pos == 0 && this.allowLoadMore) {
                            this.allowLoadMore = false;
                            if (this.prevHeight == 0) {
                                this.prevHeight = chatbox.scrollHeight
                            }
                            // load more
                            this.$emit('load-more')
                        }
                    }
                };
            },
            loadMoreScroll() {
                var chatbox = document.getElementById('chatroom-discussion-flow');
                let ref = this
                
                setTimeout(function () {
                    const height = chatbox.scrollHeight
                    let scroll = height - ref.prevHeight + 60;
                    
                    chatbox.scrollTop = scroll;
                    ref.prevHeight = chatbox.scrollHeight

                }, 100)


                setTimeout(function () { ref.allowLoadMore = true }, 1000)
            },
            setMsg(event) {
                this.message = this.message + event.target.value
            },
            insertSmiley(code) {
                this.$refs.txt.addSmiley(code)
            },
            profileView(slug) {
                this.$router.push(`/${this.$i18n.locale}/profiles/${slug}`);
            },
            newMessage() {
                if (this.message.trim().length) {
                    this.$emit('new-message', this.message)
                    this.$refs.txt.clear()
                    this.message = ''
                }
            },
            scrollToEnd() {
                var element = document.getElementById("chatroom-discussion-flow");
                element.scrollTop = element.scrollHeight;
            }
        }
    }
</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>