<template>
    <div class="form-group row">
        <label for="selectBlockEventPicture" class="col-lg-3 field-label">Visuel</label>
        <div class="col-lg-9">
            <div class="row">
                <div class="col-md-6" id="block-picture">
                    <div class="picture-item">
                        <div class="spinner-border" role="status" v-show="showLoader">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="picture-item" v-if="locPicture && !showLoader">
                            <img :src="locPicture" class="img-fluid">
                        </div>
                        <div v-else>Pas encore d'image</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- Bouton -->
                    <input type="file" accept="image/*"
                           style="display: none"
                           ref="fileInput"
                           @change="uploadImage($event)"
                           id="file-input">
                    <a href="#" class="btn btn-secondary" v-if="locPicture" @click.prevent="onPickFile">{{ $t("Remplacer la photo") }}<span></span></a>
                    <a href="#" class="btn btn-secondary" v-else @click.prevent="onPickFile">{{ $t("Ajouter une photo") }}<span></span></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "content-picture",
        emits: ["input"],
        props: {
            picture: {
                type: String
            },
            newPicture: {
                type: String
            }
        },
        data() {
            return {
                locPicture: '',
                showLoader: false
            }
        },
        mounted() {
            if (this.newPicture == null || this.newPicture == '') {
                if (this.picture != null) {
                    this.locPicture = this.getPicture(this.picture)
                }
            } else {
                this.locPicture = this.getPicture(this.newPicture)
            }
        },
        methods: {
            getPicture(pict) {
                axios.get(`/api/contents/picture/${pict}`)
                    .then(response => {
                        this.locPicture = response.data
                    });
            },
            onPickFile() {
                this.$refs.fileInput.click()
            },
            uploadImage(e) {
                this.showLoader = true
                const image = e.target.files[0];
                // Preview image
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.locPicture = e.target.result;
                };

                // Upload image
                let data = new FormData();
                data.append('name', image.name);
                data.append('file', image);

                let config = {
                    header: {
                        'Content-Type': 'image/png'
                    }
                }

                axios.post(
                    '/api/admincontents/picture-upload',
                    data,
                    config
                ).then(
                    response => {
                        //console.log('image upload response > ', response.data)
                        if (response.statusText == 'OK') {
                            //this.locPicture = this.require('@/upload/' + response.data)
                            this.showLoader = false
                            this.$emit("input", response.data);
                        }
                    }
                )
            }
        }
    }
</script>