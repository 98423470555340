<template>
    <div id="search-filters-settings-form-1" class="d-flex">
        <div class="settings-form-col">
            <div class="form-group">
                <label for="selectGenre" class="field-label">{{ $t('fields.f-gender') }}</label>
                <multi-select name="genders" :items="genders" :selected="locSearch.gender" v-if="genders"></multi-select>
            </div>
            <div class="form-group mt-3">
                <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" id="targetsOnline" value="true" v-model="locSearch.isOnline">
                    <label class="custom-control-label" for="targetsOnline">{{ $t('Est en ligne') }} <div class="online"><img src="../../../public/images/picto-online.svg" /></div></label>
                </div>
            </div>
        </div>
        <div class="settings-form-col">
            <div class="form-group">
                <label for="distance" class="field-label">{{ $t('Distance') }}: <span id="variable-fetched-from-slider-position">{{search.distance}} km</span></label>
                <input type="range" class="custom-range" min="5" max="200" step="5" id="distance" v-model="locSearch.distance">
            </div>
        </div>
        <div class="settings-form-col">
            <label class="field-label">{{ $t("Age") }}</label>
            <div class="form-inline">
                <custom-select :options="minAges" :unit="$t('ans')" :selectid="locSearch.minAge.toString()" @input="locSearch.minAge = $event;setMinMaxAge()" />
                <span class="form-conjunction"> {{ $t("à") }} </span>
                <custom-select :options="maxAges" :unit="$t('ans')" :selectid="locSearch.maxAge.toString()" @input="locSearch.maxAge = $event;setMinMaxAge()" />
            </div>
            </div>
            <div class="settings-form-col">
                <div class="form-group">
                    <label for="inputPseudo" class="field-label">{{ $t("Rechercher par pseudo") }}</label>
                    <input type="text" id="inputPseudo" class="form-control" v-model="locSearch.pseudo">
                </div>
            </div>
        </div>
    </template>

    <script>
        //import MultiSelectFilter from "./MultiSelectFilter.vue";
        import MultiSelect from "@/components/shared/fields/MultiSelect.vue";
        import CustomSelect from "@/components/shared/fields/CustomSelect.vue";
        export default {
            name: "quick-search",
            components: {
                MultiSelect,
                CustomSelect
            },
            props: {
                search: {
                    type: Object,
                    required: true
                },
            },
            data() {
                return {
                    genders: [
                        { id: 'W', slug: 'W' },
                        { id: 'M', slug: 'M' },
                        { id: 'O', slug: 'O' },
                    ],
                    minAges: [],
                    maxAges: []
                }

            },
            mounted() {
                this.setMinMaxAge()
            },
            computed: {
                locSearch: {
                    get() { return this.search },
                    set(locSearch) { this.$emit('update', locSearch) }
                }
            },
            methods: {
                setMinMaxAge() {
                    const min = parseInt(this.locSearch.minAge)
                    const max = parseInt(this.locSearch.maxAge)
                    let i = 0
                    this.minAges = []
                    for (i = 18; i < max; i++) {
                        this.minAges.push(i)
                    }
                    this.maxAges = []
                    for (i = min + 1; i < 100; i++) {
                        this.maxAges.push(i)
                    }
                }
            }
        };
    </script>

    <style scoped>
        .custom-select{
            height: 26px;
            line-height:26px;
        }
    </style>
