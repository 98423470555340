<template>
    <main role="main">

        <div class="pt-5 pb-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center mb-2">
                        <h1>{{ $t("Utilisateurs en ligne") }}</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- Bloc de filtres -->
        <div id="online-search-filters" class="bg-banner-form" v-if="search">
            <div class="container">
                <div id="online-search-filters-settings" class="d-flex pt-4 pb-4">
                    <div class="settings-form-col">
                        <div class="form-group">
                            <label for="Gender" class="field-label">{{ $t('fields.f-gender') }}</label>
                            <multi-select name="genders" :items="genders" :selected="search.gender" v-if="genders" @change="refreshData"></multi-select>
                        </div>
                    </div>
                    <div class="settings-form-col">
                        <div class="form-group mt-3">
                            <label for="distance">{{ $t('Distance') }}: <span id="variable-fetched-from-slider-position">{{search.distance}} km</span></label>
                            <input type="range" class="custom-range" min="5" max="200" step="5" id="distance" v-model="search.distance" @change="refreshData">
                        </div>
                    </div>
                    <div class="settings-form-col">
                        <label class="field-label">{{ $t('Age') }}</label>
                        <div class="form-inline">
                            <custom-select :options="minAges" :unit="$t('ans')" :selectid="search.minAge.toString()" @input="search.minAge = $event;setMinMaxAge()" />
                            <span class="form-conjunction">{{ $t('à') }}</span>
                            <custom-select :options="maxAges" :unit="$t('ans')" :selectid="search.maxAge.toString()" @input="search.maxAge = $event;setMinMaxAge()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Bloc de filtres -->
        <!-- Résultats -->
        <div id="search-results" class="mb-5 pt-3">
            <div class="container">
                <div class="row" v-if="profiles && profiles.length > 0">
                    <div class="col-md-12 mb-4 text-center">
                        <h2>{{ $t("Profils correspondants") }}</h2>
                    </div>
                    <div class="profiles-grid d-flex">
                        <profile-bloc v-for="profile in profiles" :key="profile.slug" :profile="profile"></profile-bloc>
                    </div>
                    <div class="col-md-12 text-center mt-4 mb-2" v-if="search.isMore">
                        <a class="btn btn-secondary" @click.prevent="moreProfiles">{{ $t('Plus de profils') }}</a>
                    </div>
                </div>
                <div class="row" v-else>
                    <h3>{{ $t("Il n'y a pas de profil correspondant à votre recherche") }}</h3>
                </div>
            </div>
        </div>
        <!-- END Résultats -->


    </main>
</template>

<script>
    import axios from "axios";
    //import _ from "lodash";
    import MultiSelect from "@/components/shared/fields/MultiSelect.vue";
    import ProfileBloc from "@/components/shared/ProfileBloc"
    import CustomSelect from "@/components/shared/fields/CustomSelect.vue";

    export default {
        name: 'online',
        components: {
            MultiSelect,
            ProfileBloc,
            CustomSelect
        },
        data() {
            return {
                genders: [
                    { id: 'W', slug: 'W' },
                    { id: 'M', slug: 'M' },
                    { id: 'O', slug: 'O' },
                ],
                search: null,
                profiles: null,
                minAges: [],
                maxAges: []
            }
        },
        mounted() {
            var ref = this
            this.$store.getters.getConnection.on("Connected", function (username, isConnected) {
                const user = ref.profiles.filter(p => p.userName == username)
                if (user != undefined && user != null) {
                    user[0].isOnline = isConnected;
                }
            })
        },
        methods: {
            setData(data) {
                this.search = data.onlineSearch
                this.profiles = data.profileBlocs
                this.setMinMaxAge()
            },
            refreshData() {
                this.setMinMaxAge()
                axios.post("/api/online/research", this.search).then(response => {
                    this.setData(response.data);
                });
            },
            moreProfiles() {
                this.search.page++
                this.refreshData()
            },
            setMinMaxAge() {
                const min = parseInt(this.search.minAge)
                const max = parseInt(this.search.maxAge)
                let i = 0
                this.minAges = []
                for (i = 18; i < max; i++) {
                    this.minAges.push(i)
                }
                this.maxAges = []
                for (i = min + 1; i < 100; i++) {
                    this.maxAges.push(i)
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.get("/api/online/search")
                .then(response => {
                    next(vm => vm.setData(response.data));
                })
        }
    }
</script>)